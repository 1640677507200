import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import homeImg from '../../assets/images/home.svg';
import plusImg from '../../assets/images/plus.svg';
import profileImg from '../../assets/images/person.svg';
import contactImg from '../../assets/images/paper-plane.svg';
import logoutImg from '../../assets/images/log-out_white.svg';
import { COLORS } from '../../assets/css/_colors';

const Wrapper = styled.div`
    display: none;
    flex-direction: column;
    /* justify-content: center; */
    text-align: center;
    padding: 2rem;
    position: absolute;
    width: 100%;
    /* top: ${(top) => top}; */
    left: 0;
    z-index: 100;
    transition: transform 0.3s ease-in-out;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
    background-color: ${COLORS.green};
    height: 100%;
    /* height: 100vh; */

    @media (max-width: 767px) {
        display: flex;
    }
`;

const Icon = styled.img`
    width: 20px;
    margin-right: 10px;
`;

const LinkContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 200px;
    margin: 0 auto;
    border-bottom: 1px solid ${COLORS.green};

    p:hover {
        text-decoration: underline;
    }
`;

const CustomButton = styled.button`
    color: white;
    background-color: transparent;
    border: none;
    font-size: 17px;
    cursor: pointer;
    transition: 0.5s all ease;
    width: auto;
    padding: 10px;

    :hover {
        color: ${COLORS.grayLight};
    }
`;

const Menu = (props) => {
    const history = useHistory();
    const [linkMarkup, setLinkMarkup] = useState([]);
    const [yOffset, setYOffset] = useState(0);
    const elementRef = useRef(null);
    const [elementHeight, setElementHeight] = useState();
    const links = [
        {
            label: 'Početna',
            link: '/',
            imgUrl: homeImg,
        },
        {
            label: 'Dodaj upit',
            link: '/new/detail/empty/false',
            imgUrl: plusImg,
        },
        {
            label: 'Profil',
            link: '/profile',
            imgUrl: profileImg,
        },
        {
            label: 'Lokacije',
            link: '/client/list',
            imgUrl: profileImg,
        },
        {
            label: 'Kontakt',
            link: '/contact-us',
            imgUrl: contactImg,
        },
        {
            label: 'Odjava',
            link: '/login',
            imgUrl: logoutImg,
        },
    ];

    useEffect(() => {
        const url = window.location.pathname.slice(15);
        setLinkMarkup(
            links.map((link, index) => {
                if (url === link.link) {
                    return (
                        <LinkContainer key={index}>
                            <Icon src={link.imgUrl} />
                            <CustomButton
                                type='button'
                                style={{
                                    borderBottom: '1px solid white',
                                }}
                                onClick={(e) => (
                                    history.push(link.link),
                                    (document.body.style.overflow = 'scroll')
                                )}
                            >
                                {link.label}
                            </CustomButton>
                        </LinkContainer>
                    );
                } else {
                    return (
                        <LinkContainer key={index}>
                            <Icon src={link.imgUrl} />
                            <CustomButton
                                type='button'
                                onClick={(e) => (
                                    history.push(link.link),
                                    (document.body.style.overflow = 'scroll')
                                )}
                            >
                                {link.label}
                            </CustomButton>
                        </LinkContainer>
                    );
                }
            })
        );
    }, []);

    const onLinkClick = (link) => {
        document.body.style.overflow = 'scroll';
    };

    useEffect(() => {
        setYOffset(window.innerHeight / 2 + window.pageYOffset - 150);
    }, [window.pageYOffset]);

    useEffect(() => {
        setElementHeight(elementRef.current.clientHeight / 2);
    }, []);

    return (
        <Wrapper open={props.open} top={props.top}>
            <div ref={elementRef} style={{ paddingTop: yOffset }}>
                {linkMarkup}
            </div>
        </Wrapper>
    );
};

export default Menu;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../assets/css/_colors';
import warningIcon from '../../assets/images/warning.svg';

const Container = styled.div`
    color: ${COLORS.redErrorText};
    background-color: ${COLORS.redErrorBackground};
    border-color: ${COLORS.redErrorBorder};

    font-size: 15px;
    padding: 0 30px;
    border-radius: 4px;
    display: inline-flex;
    transition: all 1s ease;
    width: 100%;

    p {
        margin-left: 10px;
    }

    @media (max-width: 767px) {
        padding: 0 20px;
        /* margin: 0 20px; */
        margin: 0;
    }
`;

const Error = ({ msg, style }) => {
    useEffect(() => {}, []);
    return (
        <Container style={style}>
            <img src={warningIcon} />
            <p>{msg}</p>
        </Container>
    );
};
export default Error;

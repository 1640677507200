import { API_URL } from '../../constants/index';

export async function registerMaster(data) {
    try {
        const res = await fetch(`${API_URL}/offerRequest/master`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Token: data.Token,
                UserId: data.UserId,
            },
            body: JSON.stringify({
                Address: data.Address,
                DeliveryAddress: data.DeliveryAddress,
                DeliveryMethod: data.DeliveryMethod,
                BillingMethod: data.BillingMethod,
                BillingMethodName: data.BillingMethodName,
                DeliveryMethodName: data.DeliveryMethodName,
                ClientId: data.ClientId,
            }),
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function getMasterInfo(token, year, number) {
    try {
        const res = await fetch(
            `${API_URL}/offerRequest/master/info/${year}/${number}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Token: token,
                },
            }
        );
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function getMasterListByClientId(token, userId, clientId) {
    try {
        const res = await fetch(
            `${API_URL}/offerRequest/master/list/by-client/${clientId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Token: token,
                    UserId: userId,
                },
            }
        );
        return res.status;
    } catch (e) {
        throw e;
    }
}

export async function getMasterListByStatusClient(
    token,
    userId,
    status,
    client
) {
    try {
        const res = await fetch(
            `${API_URL}/offerRequest/master/list/${status}/${client}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Token: token,
                    UserId: userId,
                },
            }
        );
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function cancelMaster(token, year, number) {
    try {
        const res = await fetch(
            `${API_URL}/offerRequest/master/cancel/${year}/${number}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Token: token,
                },
            }
        );
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function updateMaster(data, year, number) {
    try {
        const res = await fetch(
            `${API_URL}/offerRequest/master/${year}/${number}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Token: data.Token,
                },
                body: JSON.stringify({
                    Address: data.Address,
                    DeliveryAddress: data.DeliveryAddress,
                    DeliveryMethod: data.DeliveryMethod,
                    BillingMethod: data.BillingMethod,
                    BillingMethodName: data.BillingMethodName,
                    DeliveryMethodName: data.DeliveryMethodName,
                    ClientId: data.ClientId,
                }),
            }
        );
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

// Brisanje upita -> ne brisam upite nego im mjenjam status
// export async function deleteMaster(token, year, number) {
//     try {
//         const res = await fetch(
//             `${API_URL}/offerRequest/master/${year}/${number}`,
//             {
//                 method: 'DELETE',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     Token: token,
//                 },
//             }
//         );
//         if (res.status === 200) {
//             const responseData = await res.json();
//             return responseData;
//         } else {
//             const json = await res.json();
//             throw json.Message;
//         }
//     } catch (e) {
//         throw e;
//     }
// }

// Sortiranje po statusu mastera -> sortiram po statusu mastera i clientId-u
export async function getMasterListByStatus(token, userId, status) {
    try {
        const res = await fetch(
            `${API_URL}/offerRequest/master/list/${status}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Token: token,
                    UserId: userId,
                },
            }
        );
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonLoading } from '../Elements/CustomButton';
import { getUserInfo, updateUserInfo } from '../../services/user/info';
import { SpinnerBtn, Spinner } from '../Elements/Spinner';
import { Bar, Label } from '../Elements/CustomInput';
import { COLORS } from '../../assets/css/_colors';
import { Form, Input, Row, Element, RowAddress } from '../../assets/css/_forms';
import Error from '../Elements/Error';
import { LocalError } from '../../assets/css/_elements';

export const ChangeUserInfo = () => {
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = JSON.parse(localStorage.getItem('userId'));
    const [firstnameCSS, setFirstNameCSS] = useState({ top: '0px' });
    const [lastnameCSS, setLastNameCSS] = useState({ top: '0px' });
    const [emailCSS, setEmailCSS] = useState({ top: '0px' });
    const [phoneCSS, setPhoneCSS] = useState({ top: '30px' });
    const [userType, setUserType] = useState('');
    const [serverError, setServerError] = useState('');
    const [saveBtnStyle, setSaveBtnStyle] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingForm, setLoadingForm] = useState(true);
    const {
        register,
        handleSubmit,
        setValue,
        setError,
        errors,
        watch,
    } = useForm();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const userInfo = await getUserInfo(token, userId);
            setLoadingForm(false);
            setValue('firstName', userInfo.FirstName);
            setValue('lastName', userInfo.LastName);
            setValue('email', userInfo.Email);
            setUserType(userInfo.Type);
            if (userInfo.Phone !== '') {
                setPhoneCSS({ top: '0px' });
                setValue('phone', userInfo.Phone);
            }
        } catch (e) {
            setLoadingForm(false);
            setServerError(e);
        }
    };

    const onInfoSubmit = async (data) => {
        setLoading(true);
        try {
            setSaveBtnStyle({
                backgroundColor: 'transparent',
                color: `${COLORS.green}`,
                border: `2px solid ${COLORS.green}`,
                paddingTop: '0',
            });
            await updateUserInfo(data, token, userId, userType);
            localStorage.setItem(
                'actionMessage',
                'Uspješna promjena korisničkih podataka!'
            );
            window.location.reload(false);
        } catch (e) {
            console.log(e);
            setServerError(e);
            setLoading(false);
            setSaveBtnStyle({
                backgroundColor: `${COLORS.green}`,
                color: 'white',
                border: `2px solid ${COLORS.green}`,
            });
        }
    };

    return (
        <div>
            {loadingForm ? (
                <Spinner />
            ) : (
                <Form
                    style={{ width: '250px', autoComplete: 'none' }}
                    onSubmit={handleSubmit(onInfoSubmit)}
                >
                    <Row style={{ gridTemplateColumns: '100%' }}>
                        <Element
                            width='100%'
                            onBlur={() =>
                                watch('firstName') === ''
                                    ? setFirstNameCSS({
                                          top: '30px',
                                      })
                                    : setFirstNameCSS({
                                          top: '0px',
                                      })
                            }
                            onFocus={() => setFirstNameCSS({ top: '0px' })}
                        >
                            <Input
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Unesite ime',
                                    },
                                    maxLength: {
                                        value: 25,
                                        message:
                                            'Ime smije imati najviše 25 znakova',
                                    },
                                })}
                                name='firstName'
                                type='text'
                                onChange={(e) => setServerError('')}
                                autoComplete='off'
                            />
                            <Bar style={{ marginRight: '10px' }} />
                            <Label style={firstnameCSS} text='Ime' />
                            {errors.firstName && (
                                <LocalError>
                                    {errors.firstName.message}
                                </LocalError>
                            )}
                        </Element>
                    </Row>

                    <Row style={{ gridTemplateColumns: '100%' }}>
                        <Element
                            width='100%'
                            onBlur={() =>
                                watch('lastName') === ''
                                    ? setLastNameCSS({
                                          top: '30px',
                                      })
                                    : setLastNameCSS({
                                          top: '0px',
                                      })
                            }
                            onFocus={() => setLastNameCSS({ top: '0px' })}
                        >
                            <Input
                                name='lastName'
                                type='text'
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Unesite prezime',
                                    },
                                    maxLength: {
                                        value: 50,
                                        message:
                                            'Prezime smije imati najviše 50 znakova',
                                    },
                                })}
                                onChange={(e) => setServerError('')}
                                autoComplete='none'
                            />
                            <Bar />
                            <Label style={lastnameCSS} text='Prezime' />
                            {errors.lastName && (
                                <LocalError>
                                    {errors.lastName.message}
                                </LocalError>
                            )}
                        </Element>
                    </Row>

                    <Row style={{ gridTemplateColumns: '100%' }}>
                        <Element
                            width='100%'
                            onBlur={() =>
                                watch('email') === ''
                                    ? setEmailCSS({
                                          top: '30px',
                                      })
                                    : setEmailCSS({
                                          top: '0px',
                                      })
                            }
                            onFocus={() => setEmailCSS({ top: '0px' })}
                        >
                            <Input
                                name='email'
                                type='text'
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Unesite email',
                                    },
                                    maxLength: {
                                        value: 50,
                                        message:
                                            'Email može sadržavati najviše 50 znakova',
                                    },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'Unesite uspravan email',
                                    },
                                })}
                                onChange={(e) => setServerError('')}
                                autoComplete='none'
                            />
                            <Bar />
                            <Label style={emailCSS} text='Email' />
                            {errors.email && (
                                <LocalError>{errors.email.message}</LocalError>
                            )}
                        </Element>
                    </Row>

                    <Row style={{ gridTemplateColumns: '100%' }}>
                        <Element
                            width='100%'
                            onBlur={() =>
                                watch('phone') === ''
                                    ? setPhoneCSS({
                                          top: '30px',
                                      })
                                    : setPhoneCSS({
                                          top: '0px',
                                      })
                            }
                            onFocus={() => setPhoneCSS({ top: '0px' })}
                        >
                            <Input
                                name='phone'
                                type='text'
                                ref={register({
                                    maxLength: {
                                        value: 25,
                                        message:
                                            'Telefon može sadržavati najviše 25 znakova',
                                    },
                                })}
                                onChange={(e) => setServerError('')}
                                autoComplete='none'
                            />
                            <Bar />
                            <Label style={phoneCSS} text='Telefon' />
                            {errors.phone && (
                                <LocalError>{errors.phone.message}</LocalError>
                            )}
                        </Element>
                    </Row>

                    {saveBtnStyle && (
                        <ButtonLoading style={saveBtnStyle} type='submit'>
                            {loading && <SpinnerBtn />}
                            Spremi
                        </ButtonLoading>
                    )}

                    <Input
                        style={{ display: 'none' }}
                        name='off'
                        type='text'
                        autoComplete='off'
                    />

                    {serverError !== '' && <Error msg={serverError} />}
                </Form>
            )}
        </div>
    );
};

import React from 'react';
import styled from 'styled-components';
import CopyRight from './CopyRight';
import Info from './Info';
import Links from './Links';
import { COLORS } from '../../assets/css/_colors';

const Wrapper = styled.div`
    width: 100%;
    background: ${COLORS.grayDarkBackground};
    color: ${COLORS.grayLight};
    height: 410px;
    position: absolute;
    bottom: 0;

    @media (max-width: 480px) {
        height: 560px;
    }
`;

const Footer = (props) => {
    return (
        <Wrapper>
            <Info />
            <Links />
            <CopyRight />
        </Wrapper>
    );
};

export default Footer;

import { API_URL } from '../../constants/index';

export async function handleRegister(data, userType) {
    try {
        let OIB1, OIB2;
        if (userType === '1') {
            OIB1 = data.firmOIB.substring(0, 11);
            OIB2 = data.firmOIB.substring(data.firmOIB.length - 3);
        } else {
            OIB1 = data.userOIB;
            OIB2 = null;
        }

        const res = await fetch(`${API_URL}/registeroriginal`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                FirstName: data.firstName,
                LastName: data.lastName,
                FirmName: data.firmName,
                Phone: data.phone,
                Email: data.email,
                Type: userType,
                OIB1: OIB1,
                OIB2: OIB2,
                Address: data.address,
                ZipCode: data.zipCode,
                City: data.city,
                DeliveryAddress: data.deliveryAddress,
                DeliveryCity: data.deliveryCity,
                DeliveryZipCode: data.deliveryZipCode,
            }),
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

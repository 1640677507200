import { API_URL } from "../../constants/index";

export async function updateUsername(token, userId, data) {
    try {
        const res = await fetch(`${API_URL}/user/username`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Token: token,
                UserId: userId,
            },
            body: JSON.stringify({
                Username: data.username,
                Password: data.password,
            }),
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function updatePassword(token, userId, data) {
    try {
        const res = await fetch(`${API_URL}/user/password`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Token: token,
                UserId: userId,
            },
            body: JSON.stringify({
                Password: data.passwordOld,
                PasswordNew: data.passwordNew,
            }),
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            const response = {
                Message: json.Message,
                status: res.status,
            };
            throw response;
        }
    } catch (e) {
        throw e;
    }
}

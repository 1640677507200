import { API_URL } from '../../constants/index';

export async function getClientData(token, userId, clientId) {
    try {
        const res = await fetch(`${API_URL}/user/client/${clientId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
                UserId: userId,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function getClientList(token, userId) {
    try {
        const res = await fetch(`${API_URL}/user/client/list`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
                UserId: userId,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function registerClient(token, userId, data) {
    try {
        const res = await fetch(`${API_URL}/user/client`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
                UserId: userId,
            },
            body: JSON.stringify({
                FirstName: data.firstName,
                LastName: data.lastName,
                KomitName: data.firmName,
                Phone: data.phone,
                Email: data.email,
                OIB1: data.OIB1,
                OIB2: data.OIB2,
                Address: data.address,
                ZipCode: data.zipCode,
                City: data.city,
                DeliveryAddress: data.deliveryAddress,
                DeliveryCity: data.deliveryCity,
                DeliveryZipCode: data.deliveryZipCode,
            }),
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function updateClient(token, userId, data, selectedClient) {
    try {
        const res = await fetch(`${API_URL}/user/client`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
                UserId: userId,
            },
            body: JSON.stringify({
                Id: selectedClient,
                KomitName: data.firmName,
                OIB1: data.OIB1,
                OIB2: data.OIB2,
                Address: data.address,
                City: data.city,
                ZipCode: data.zipCode,
                DeliveryAddress: data.deliveryAddress,
                DeliveryCity: data.deliveryCity,
                DeliveryZipCode: data.deliveryZipCode,
            }),
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function deleteClient(token, userId, clientId) {
    try {
        const res = await fetch(`${API_URL}/user/client/${clientId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
                UserId: userId,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

import { API_URL } from '../../constants/index';

export async function getDetailInfo(token, id) {
    try {
        const res = await fetch(`${API_URL}/offerRequest/detail/info/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function registerDetail(token, data) {
    try {
        const res = await fetch(`${API_URL}/offerRequest/detail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
            },
            body: JSON.stringify({
                MasterNumber: data.MasterNumber,
                MasterYear: data.MasterYear,
                Description: data.Description,
                ArticleId: data.ArticleId,
                RequiredQuantity: data.RequiredQuantity,
                ArticlePrice: data.ArticlePrice,
                Price: data.Price,
                ArticleName: data.ArticleName,
                MeasuringUnit: data.MeasuringUnit,
            }),
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function getDetailStatusList(token, userId) {
    try {
        const res = await fetch(`${API_URL}/offerRequest/detail/status`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
                UserId: userId,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function getDetailListByStatus(token, year, number, status) {
    try {
        const res = await fetch(
            `${API_URL}/offerRequest/detail/list/${status}/${year}/${number}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Token: token,
                },
            }
        );
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function getDetailList(token, year, number) {
    try {
        const res = await fetch(
            `${API_URL}/offerRequest/detail/list/${year}/${number}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Token: token,
                },
            }
        );
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function updateDetail(token, id, data) {
    try {
        const res = await fetch(`${API_URL}/offerRequest/detail/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
            },
            body: JSON.stringify({
                MasterNumber: data.MasterNumber,
                MasterYear: data.MasterYear,
                Description: data.Description,
                ArticleName: data.ArticleName,
                ArticleId: data.ArticleId,
                RequiredQuantity: data.RequiredQuantity,
                ArticlePrice: data.ArticlePrice,
                Price: data.Price,
                MeasuringUnit: data.MeasuringUnit,
            }),
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function deleteDetail(token, id) {
    try {
        const res = await fetch(`${API_URL}/offerRequest/detail/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function cancelDetail(token, id) {
    try {
        console.log(token, id);
        const res = await fetch(`${API_URL}/offerRequest/detail/cancel/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

import React, { useState } from 'react';
import styled from 'styled-components';
import { DefaultInfo } from '../../Elements/DefaultInfo';
import Footer from '../../Footer/Footer';
import { ButtonBasic } from '../../Elements/CustomButton';
import { ChangeUserInfo } from '../../Forms/ChangeUserInfo';
import { ChangeUsername } from '../../Forms/ChangeUsername';
import { ChangePassword } from '../../Forms/ChangePassword';
import { FooterForm } from '../../Elements/FooterForm';
import { Wrapper, Container } from '../../../assets/css/_pages';
import profileIcon from '../../../assets/images/profile.png';
import { Notification } from '../../Elements/Notification';
import arrowIcon from '../../../assets/images/arrow_down.svg';

const Img = styled.img`
    width: 150px;
    height: 180px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    transition: all 0.4s ease;

    @media (max-width: 767px) {
        width: 120px;
        height: 150px;
    }
`;

const Buttons = styled.div`
    display: grid;
    grid-template-columns: 30% auto 30%;
    margin: 20px auto;
    padding: 5px;

    @media (max-width: 767px) {
        button {
            margin: 5px 0 !important;
            padding: auto;
        }
    }

    @media (max-width: 480px) {
        grid-template-columns: 100%;
        margin: 20px 10px;
    }
`;

const Selected = styled.div`
    display: grid;
    grid-template-columns: 180px auto 180px;
    justify-items: center;
    padding: 5px;
    margin-top: 40px;

    @media (max-width: 480px) {
        grid-template-columns: 100%;
        grid-template-rows: repeat(3, 33%);
        align-items: center;
        margin: 20px auto;

        img {
            transform: rotate(-90deg);
        }
    }
`;

const ButtonsContainer = styled.div`
    display: grid;

    @media (max-width: 480px) {
        grid-template-columns: 10% auto;
    }
`;

const Profile = (props) => {
    const [userInfoDisplay, setUserInfoDisplay] = useState(true);
    const [usernameDisplay, setUsernameDisplay] = useState(false);
    const [passwordDisplay, setPasswordDisplay] = useState(false);

    function onChangeInfoClick() {
        setUserInfoDisplay(true);
        setUsernameDisplay(false);
        setPasswordDisplay(false);
    }

    function onChangeUsernameClick() {
        setUserInfoDisplay(false);
        setUsernameDisplay(true);
        setPasswordDisplay(false);
    }

    function onChangePasswordClick() {
        setUserInfoDisplay(false);
        setUsernameDisplay(false);
        setPasswordDisplay(true);
    }

    return (
        <Wrapper>
            <Notification />
            <DefaultInfo />
            <Container style={{ maxWidth: '700px' }}>
                <Img src={profileIcon} />

                <ButtonsContainer>
                    <Selected>
                        <div>{userInfoDisplay && <img src={arrowIcon} />}</div>
                        <div>{usernameDisplay && <img src={arrowIcon} />}</div>
                        <div>{passwordDisplay && <img src={arrowIcon} />}</div>
                    </Selected>

                    <Buttons>
                        <ButtonBasic type='button' onClick={onChangeInfoClick}>
                            promijeni podatke
                        </ButtonBasic>
                        <ButtonBasic
                            style={{ margin: '0 10px' }}
                            type='button'
                            onClick={onChangeUsernameClick}
                        >
                            promijeni korisničko ime
                        </ButtonBasic>
                        <ButtonBasic
                            type='button'
                            onClick={onChangePasswordClick}
                        >
                            promijeni lozinku
                        </ButtonBasic>
                    </Buttons>
                </ButtonsContainer>

                {userInfoDisplay && (
                    <div>
                        <ChangeUserInfo />
                        <FooterForm
                            text={
                                'Nakon što se stranica osvježi, Vaši podatci će biti promijenjeni!'
                            }
                        />
                    </div>
                )}

                {usernameDisplay && (
                    <div>
                        <ChangeUsername />
                        <FooterForm
                            text={
                                'Korisnički podaci stići će na Vašu e-mail adresu!'
                            }
                        />
                    </div>
                )}

                {passwordDisplay && (
                    <div>
                        <ChangePassword />
                        <FooterForm
                            style={{ marginTop: '50px' }}
                            text={
                                'Korisnički podaci stići će na Vašu e-mail adresu!'
                            }
                        />
                    </div>
                )}
            </Container>
            <Footer />
        </Wrapper>
    );
};

export default Profile;

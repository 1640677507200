import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../assets/css/_colors';
import { useHistory, useParams } from 'react-router-dom';
import monitorIcon from '../../../assets/images/monitor.jpg';
import { DataContext } from '../../../context/ArticleContext';

const Article = styled.div`
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.3s ease;
    background-color: white;
    position: relative;
    display: grid;
    grid-template-rows: repeat(3, auto);
    height: 100%;
    width: 100%;

    &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    img {
        width: 100%;
        height: auto;
        max-height: 150px;
        display: block;
        margin: auto;
    }
`;

const ContainerImg = styled.div`
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
`;

const ContainerInfo = styled.div`
    max-width: fit-content;
    /* border-top: 1px solid #e4e3e3; */
    margin: 0 10px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 2;
    height: fit-content;
`;

const ItemName = styled.p`
    font-size: 14px;
    color: ${COLORS.grayTextLight};
    font-weight: bold;
    align-self: flex-start;
    max-width: 300px;
    overflow: scroll;
`;

const ArticleBottom = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 50%);
`;

const Price = styled.div`
    margin: 10px 0;
    align-self: start;
    height: 30px;
    display: flex;

    span:nth-child(1) {
        color: #0093c1;
        font-size: 27px;
        font-weight: bold;
        align-self: flex-end;
    }
    span:nth-child(2) {
        color: #0093c1;
        font-size: 18px;
        font-weight: bold;
        align-self: start;
        height: 100%;
        margin: 0 5px;
    }
    span:nth-child(3) {
        font-weight: bold;
        margin-left: 5px;
        color: #ccc;
        font-size: 18px;
        align-self: flex-end;
    }
`;

const ArticleCard = ({
    currentArticleId,
    name,
    decimalPrice,
    wholePrice,
    onClick,
    articlesPage,
}) => {
    let { year, number, id } = useParams();
    const history = useHistory();
    const { setSelectedArticleId } = useContext(DataContext);

    const onArticleClick = async () => {
        setSelectedArticleId(currentArticleId);
        if (id) {
            history.push(
                `/edit/detail/${year}/${number}/${id}/${currentArticleId}/true`
            );
        } else {
            year === 'empty'
                ? history.push(`/new/detail/${currentArticleId}/true`)
                : history.push(
                      `/new/detail/${year}/${number}/${currentArticleId}/true`
                  );
        }
    };

    return (
        <div>
            {articlesPage ? (
                <Article onClick={onArticleClick}>
                    {/* <ContainerImg>
                        <img src={monitorIcon} alt='item' />
                    </ContainerImg> */}

                    <ContainerInfo>
                        <ItemName>{name}</ItemName>

                        <ArticleBottom>
                            <Price>
                                <span>{wholePrice}</span>
                                <span>{decimalPrice}</span>
                                <span>kn</span>
                            </Price>
                        </ArticleBottom>
                    </ContainerInfo>
                </Article>
            ) : (
                <Article onClick={onClick}>
                    {/* <ContainerImg>
                        <img src={monitorIcon} alt='item' />
                    </ContainerImg> */}

                    <ContainerInfo>
                        <ItemName>{name}</ItemName>

                        <ArticleBottom>
                            <Price>
                                <span>{wholePrice}</span>
                                <span>{decimalPrice}</span>
                                <span>kn</span>
                            </Price>
                        </ArticleBottom>
                    </ContainerInfo>
                </Article>
            )}
        </div>
    );
};
export default ArticleCard;

import React from 'react';
import styled from 'styled-components';
import { ContactForm } from '../../Forms/ContactForm';
import { DefaultInfo } from '../../Elements/DefaultInfo';
import Footer from '../../Footer/Footer';
import { Wrapper, Container } from '../../../assets/css/_pages';
import { Title } from '../../../assets/css/_elements';
import { ReactComponent as MailIcon } from '../../../assets/images/sendMail.svg';

const ContainerContact = styled(Container)`
    width: 700px;
    @media (max-width: 767px) {
        width: 100%;
    }

    @media (max-width: 480px) {
    }
`;

export const ContactPage = () => {
    return (
        <Wrapper>
            <DefaultInfo />
            <ContainerContact>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '50px',
                    }}
                >
                    <MailIcon style={{ width: '100px', marginRight: '10px' }} />
                    <Title
                        style={{
                            marginLeft: '10px',
                            marginBottom: '50px',
                            textAlign: 'center',
                        }}
                    >
                        Pošaljite nam mail
                    </Title>
                </div>
                <ContactForm />
            </ContainerContact>
            <Footer />
        </Wrapper>
    );
};

import React, { useEffect, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonBasic } from '../../Elements/CustomButton';
import styled from 'styled-components';
import { DataContext } from '../../../context/ArticleContext';
import { COLORS } from '../../../assets/css/_colors';
import plusIcon from '../../../assets/images/circle-plus.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/circle-plus.svg';
import { ReactComponent as MinusIcon } from '../../../assets/images/circle-minus.svg';

const PlusMinusButtons = styled(ButtonBasic)`
    width: auto;
    border: none;
    padding: 0;

    :hover {
        background-color: transparent;
    }
`;

const Container = styled.div`
    margin-left: 20px;
    width: 200px;

    p {
        color: ${COLORS.grayTextLight};
    }

    @media (max-width: 480px) {
        margin: 0;
        margin-top: 20px;
    }
`;

const ArticleQuantity = ({ selectedArticle, style, text }) => {
    const [minusBtnColor, setMinusBtnColor] = useState(
        `${COLORS.grayTextLight}`
    );
    const [plusBtnColor, setPlusBtnColor] = useState(`${COLORS.grayTextLight}`);
    const { register, setValue, watch } = useForm();
    const {
        articlePrice,
        offerPrice,
        setOfferPrice,
        articleQuantity,
        setArticleQuantity,
    } = useContext(DataContext);

    useEffect(() => {
        setOfferPrice((articlePrice * articleQuantity).toFixed(2));
        setValue('quantityInput', articleQuantity);
    }, []);

    function reduceQuantity() {
        if (watch('quantityInput') !== '1') {
            setValue('quantityInput', parseInt(watch('quantityInput')) - 1);
            setArticleQuantity(watch('quantityInput'));
            setOfferPrice((articlePrice * watch('quantityInput')).toFixed(2));
        }
    }

    function addQuantity() {
        setValue('quantityInput', parseInt(watch('quantityInput')) + 1);
        setArticleQuantity(watch('quantityInput'));
        setOfferPrice((articlePrice * watch('quantityInput')).toFixed(2));
    }

    return (
        <Container style={style}>
            <p
                style={{
                    marginTop: '0',
                    textAlign: 'center',
                }}
            >
                {text ? text : 'ODABERITE KOLIČINU'}
            </p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <PlusMinusButtons
                    type='button'
                    onClick={reduceQuantity}
                    onMouseEnter={() =>
                        setMinusBtnColor(`${COLORS.grayTextDark}`)
                    }
                    onMouseLeave={() =>
                        setMinusBtnColor(`${COLORS.grayTextLight}`)
                    }
                >
                    <MinusIcon width='30px' fill={minusBtnColor} />
                </PlusMinusButtons>

                <input
                    style={{
                        fontSize: '30px',
                        border: `1px solid ${COLORS.grayLight}`,
                        border: 'none',
                        width: '50px',
                        margin: '0 5px',
                        borderRadius: '4px',
                        textAlign: 'center',
                    }}
                    disabled
                    name='quantityInput'
                    type='text'
                    ref={register({
                        required: {
                            value: true,
                            message:
                                'Morate unjeti količinu za odabrani artikl',
                        },
                        pattern: {
                            value: /^[1-9\b]+$/,
                            message: 'Količina mora biti pozitivan broj',
                        },
                    })}
                />
                <PlusMinusButtons
                    type='button'
                    onClick={addQuantity}
                    onMouseEnter={() =>
                        setPlusBtnColor(`${COLORS.grayTextDark}`)
                    }
                    onMouseLeave={() =>
                        setPlusBtnColor(`${COLORS.grayTextLight}`)
                    }
                >
                    <PlusIcon width='30px' fill={plusBtnColor} />
                </PlusMinusButtons>
            </div>
            {selectedArticle && (
                <p style={{ textAlign: 'center' }}>
                    Iznos:
                    <span
                        style={{
                            marginLeft: '5px',
                            color: `${COLORS.grayTextDark}`,
                            fontWeight: 'bold',
                        }}
                    >
                        {offerPrice} kn
                    </span>
                </p>
            )}
        </Container>
    );
};
export default ArticleQuantity;

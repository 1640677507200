import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../assets/css/_colors';
import arrowUpIcon from '../../assets/images/arrow_up.svg';

export const ButtonBasic = styled.button`
    width: auto;
    border-radius: 4px;
    font-weight: 500;
    letter-spacing: 0.02857em;
    cursor: pointer;
    transition: all 0.4s ease;
    text-transform: uppercase;
    padding: 10px 16px;
    color: ${COLORS.grayTextDark};
    font-size: 0.875rem;
    border: 1px solid ${COLORS.grayLight};
    background-color: transparent;
    display: inline-flex;
    justify-content: center;
    /* align-content: center; */

    :hover {
        background-color: ${COLORS.grayLight};
        color: white;
    }
`;

export const PageButton = styled(ButtonBasic)`
    border-radius: 3px;
    background-color: white;
    font-size: 15px;
    height: auto;
    padding: 10px 15px;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
    margin: 0 1px;
    color: ${COLORS.grayTextLight};

    :hover {
        background-color: ${COLORS.grayBorderLight};
        color: ${COLORS.grayTextLight};
    }
`;

export const PageNavButton = styled(ButtonBasic)`
    height: 37px;
    border-radius: 3px;
    border: none;
    font-size: 15px;
    padding: 0 15px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    transition: 0.5s all ease;
    color: ${COLORS.grayTextLight};

    :hover {
        background-color: ${COLORS.grayBorderLight};
    }
`;

const BtnLoading = styled(ButtonBasic)`
    margin-top: 20px;
    width: 100%;
    display: block;
    background-color: ${COLORS.green};
    color: white;
    border: 2px solid ${COLORS.green};

    img {
        position: absolute;
    }

    :hover {
        background-color: transparent !important;
        color: green !important;
        border: 2px solid ${COLORS.green} !important;
    }
`;

const BtnUp = styled.button`
    position: fixed;
    bottom: 0;
    right: 0;
    border: none;
    background-color: transparent;
    margin-right: 0px;
    margin-bottom: 40px;
    cursor: pointer;
    z-index: 10;
`;

export const ButtonPDF = styled.button`
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.02857em;
    cursor: pointer;
    border: none;
    background-color: ${COLORS.green};
    font-size: 0.875rem;
    color: white;
    padding: 10px 16px;
    transition: all 0.4s ease;

    :hover {
        opacity: 0.8;
    }
`;

const BtnAddClient = styled.button`
    position: fixed;
    bottom: 0;
    right: 0;
    border: none;
    background-color: transparent;
    margin-right: 0px;
    margin-bottom: 100px;
    cursor: pointer;
    z-index: 10;
    display: flex;

    span {
        color: ${COLORS.grayTextDark};
        margin-right: 10px;
        align-self: center;
        text-transform: uppercase;
    }
`;

export const ButtonLoading = ({ children, type, onClick, style }) => {
    return (
        <BtnLoading style={style} onClick={onClick} type={type}>
            {children}
        </BtnLoading>
    );
};

export const ButtonAddClient = ({ type, onClick, style }) => {
    return (
        <BtnAddClient style={style} onClick={onClick} type={type}>
            <img src={require('../../assets/images/plus_circle.svg')} />
        </BtnAddClient>
    );
};

export const ButtonUp = ({ type, onClick, style }) => {
    return (
        <BtnUp style={style} onClick={onClick} type={type}>
            <img alt='go up' src={arrowUpIcon} />
        </BtnUp>
    );
};

export const ButtonPdf = ({ type, onClick, style }) => {
    return (
        <ButtonPDF style={style} onClick={onClick} type={type}>
            DOWNLOAD PDF
        </ButtonPDF>
    );
};

import React, { useState, useEffect } from 'react';
import MasterList from '../../OfferRequest/Master/MasterList';
import FilterStatus from '../../Elements/FilterStatus';
import Footer from '../../Footer/Footer';
import { Title } from '../../../assets/css/_elements';
import { Wrapper, Container } from '../../../assets/css/_pages';
import { DefaultInfo } from '../../Elements/DefaultInfo';
import { Notification } from '../../Elements/Notification';

const HomePage = ({}) => {
    const [status, setStatus] = useState(100);
    const [client, setClient] = useState(100);
    const [loading, setLoading] = useState(true);

    return (
        <Wrapper>
            <Notification />
            <DefaultInfo />
            <Container>
                <Title style={{ marginBottom: '40px' }}>Upiti za ponudom</Title>

                <FilterStatus
                    setStatus={setStatus}
                    setClient={setClient}
                    client={client}
                    status={status}
                    loading={loading}
                    setLoading={setLoading}
                />

                <MasterList
                    key={status}
                    status={status}
                    client={client}
                    loading={loading}
                    setLoading={setLoading}
                />
            </Container>
            <Footer />
        </Wrapper>
    );
};

export default HomePage;

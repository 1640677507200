import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../../context/AuthContext';
import { RecoverCredentialsForm } from '../../Forms/RecoverCredentialsForm';
import { FooterForm } from '../../Elements/FooterForm';
import { COLORS } from '../../../assets/css/_colors';

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    background: ${COLORS.grayLightBackground};
    position: absolute;
    z-index: 1;
    padding: 10px;
`;

const Container = styled.div`
    max-width: 800px;
    margin: 0 auto;
    transition: all 1s ease;
    padding: 0 40px;

    @media (max-width: 480px) {
        padding: 0 20px;
    }
`;

const Title = styled.h3`
    color: ${COLORS.grayDark};
    padding-top: 50px;
    text-align: center;
    margin: 0;
    margin-bottom: 20px;
`;

const RecoverCredentials = (props) => {
    const authStore = useAuth();

    useEffect(() => {
        authStore.logout();
    }, []);

    return (
        <Wrapper>
            <Container>
                <Title>INFODATA</Title>
                <RecoverCredentialsForm />
                <FooterForm
                    text={'Korisnički podaci stići će na Vašu e-mail adresu.'}
                />
            </Container>
        </Wrapper>
    );
};

export default RecoverCredentials;

import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import ArticleList from './ArticleList';
import { useHistory, useParams } from 'react-router-dom';
import Groups from './Groups';
import { COLORS } from '../../../assets/css/_colors';
import useCustomHook from './CustomHook';
import { SelectedGroupContext } from '../../../context/WebShopContext';
import Pagination from './Pagination';
import { ButtonBasic } from '../../Elements/CustomButton';
import { EmptyState } from '../../Elements/EmptyState';
import { Spinner } from '../../Elements/Spinner';
import { DataContext } from '../../../context/ArticleContext';
import { ReactComponent as ArrowBack } from '../../../assets/images/arrow-back.svg';
import PriceFilter from './PriceFilter';
import SearchAndSortArticles from './SearchAndSortArticles';
import { sort } from '../../../utils/sort';

const Container = styled.div`
    margin: 50px;
    display: grid;
    grid-template-columns: 250px auto;

    .articleList {
        margin-left: 20px;
    }

    .backBtn__mobile {
        display: none;
    }

    @media (max-width: 1100px) {
        grid-template-columns: 100%;
        margin: 20px;
        margin-top: 0;
        padding-top: 50px;

        .articleList {
            margin-left: 0;
            margin-top: 20px;
        }

        .backBtn__desktop {
            display: none;
        }

        .backBtn__mobile {
            display: block;
            width: 200px;
        }
    }

    @media (max-width: 767px) {
        padding-top: 100px;
    }

    @media (max-width: 480px) {
        .backBtn__mobile {
            width: 100%;
        }
    }
`;

const Header = styled.div`
    display: grid;
    grid-template-columns: 210px auto;

    @media (max-width: 480px) {
        grid-template-columns: 100%;

        button {
            margin-bottom: 20px;
        }
    }
`;

const ArticlesList = ({}) => {
    let { year, number, id } = useParams();
    const { selectedArticleId } = useContext(DataContext);
    const [selectedGroup] = useContext(SelectedGroupContext);
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [filteredArticles, setFilteredArticles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(30);
    const history = useHistory();
    const [currentArticle, setCurrentArticle] = useState([]);
    const [isFilteredByPrice, setIsFilteredByPrice] = useState(false);
    const [showFilterByPrice, setShowFilterByPrice] = useState(false);
    const [priceRange, setPriceRange] = useState([]);
    const [defaultPrice, setDefaultPrice] = useState([]);
    const showArticleList = useCustomHook(
        selectedGroup,
        setLoading,
        setFilteredArticles,
        setDefaultPrice
    );

    const [selectedSort, setSelectedSort] = useState({
        value: 1,
        label: 'Prvo najjefinije',
    });

    // on custom hook -> postavljanje max cijene
    useEffect(() => {
        let mounted = true;

        if (mounted) setPriceRange([...defaultPrice]);

        return () => {
            mounted = false;
        };
    }, [defaultPrice]);

    // On price filter -> filtriraj listu artikala po cijeni
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            var filteredArrByPrice = [];
            filteredArrByPrice = showArticleList.filter((article) => {
                return (
                    article.Price >= priceRange[0] &&
                    article.Price <= priceRange[1]
                );
            });

            setFilteredArticles(sort(filteredArrByPrice, selectedSort.value));
            setCurrentPage(1);
        }

        return () => {
            mounted = false;
        };
    }, [priceRange]);

    // sortiranje arraya prilikom loadanja stranice
    useEffect(() => {
        setFilteredArticles(sort(showArticleList, selectedSort.value));
    }, [showArticleList]);

    // on page change or article list change -> promjena liste artikala po stranici
    useEffect(() => {
        setCurrentArticle(
            filteredArticles.slice(
                currentPage * perPage - perPage,
                currentPage * perPage
            )
        );
    }, [currentPage, filteredArticles, perPage]);

    const setFirstPage = () => {
        setCurrentPage(1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const onBackClick = () => {
        if (id) {
            selectedArticleId !== ''
                ? history.push(
                      `/edit/detail/${year}/${number}/${id}/${selectedArticleId}/true`
                  )
                : history.push(
                      `/edit/detail/${year}/${number}/${id}/empty/true`
                  );
        } else {
            year === 'empty'
                ? selectedArticleId !== ''
                    ? history.push(`/new/detail/${selectedArticleId}/true`)
                    : history.push(`/new/detail/empty/true`)
                : selectedArticleId !== ''
                ? history.push(
                      `/new/detail/${year}/${number}/${selectedArticleId}/true`
                  )
                : history.push(`/new/detail/${year}/${number}/empty/true`);
        }
    };

    return (
        <Container>
            <Header>
                <ButtonBasic className='backBtn__mobile' onClick={onBackClick}>
                    <ArrowBack />
                    <span
                        style={{
                            marginLeft: '10px',
                            alignSelf: 'center',
                        }}
                    >
                        povratak na upit
                    </span>
                </ButtonBasic>
                <Groups
                    className='groups'
                    setFirstPage={setFirstPage}
                    setSearchValue={setSearchValue}
                />
            </Header>

            <div className='articleList'>
                <ButtonBasic
                    style={{ marginBottom: '30px' }}
                    className='backBtn__desktop'
                    onClick={onBackClick}
                >
                    povratak na upit
                </ButtonBasic>

                <SearchAndSortArticles
                    setSearchValue={setSearchValue}
                    searchValue={searchValue}
                    setSelectedSort={setSelectedSort}
                    selectedSort={selectedSort}
                    filteredArticles={filteredArticles}
                    setFilteredArticles={setFilteredArticles}
                    showArticleList={showArticleList}
                    setCurrentPage={setCurrentPage}
                />

                {showFilterByPrice ? (
                    <PriceFilter
                        defaultPrice={defaultPrice}
                        setPriceRange={setPriceRange}
                        setIsFilteredByPrice={setIsFilteredByPrice}
                        setShowFilterByPrice={setShowFilterByPrice}
                    />
                ) : (
                    <ButtonBasic
                        style={{ marginTop: '20px' }}
                        type='button'
                        onClick={() => setShowFilterByPrice(true)}
                    >
                        uključi filter
                    </ButtonBasic>
                )}

                {loading ? (
                    <div
                        style={{
                            margin: 'auto',
                            marginTop: '30px',
                            textAlign: 'center',
                        }}
                    >
                        <Spinner />
                        <p>Loading...</p>
                    </div>
                ) : (
                    <div style={{ width: '100%' }}>
                        {currentArticle.length > 0 ? (
                            <div>
                                <Pagination
                                    loading={loading}
                                    articlesPerPage={perPage}
                                    totalArticles={filteredArticles.length}
                                    currentPage={currentPage}
                                    perPage={perPage}
                                    setPerPage={setPerPage}
                                    setCurrentPage={setCurrentPage}
                                    setFirstPage={setFirstPage}
                                    // setLastPage={setLastPage}
                                    // setPreviousPage={setPreviousPage}
                                    // nextPage={nextPage}
                                    // paginate={paginate}
                                />
                                <ArticleList showArticleList={currentArticle} />
                                <Pagination
                                    loading={loading}
                                    articlesPerPage={perPage}
                                    totalArticles={filteredArticles.length}
                                    currentPage={currentPage}
                                    perPage={perPage}
                                    setPerPage={setPerPage}
                                    setCurrentPage={setCurrentPage}
                                    setFirstPage={setFirstPage}
                                    // setLastPage={setLastPage}
                                    // setPreviousPage={setPreviousPage}
                                    // nextPage={nextPage}
                                    // paginate={paginate}
                                />
                            </div>
                        ) : (
                            <EmptyState
                                style={{ width: '100%' }}
                                styleImg={{ width: '200px' }}
                                msg={'Nema rezultate pretrage!'}
                            />
                        )}
                    </div>
                )}
            </div>
        </Container>
    );
};

export default ArticlesList;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ButtonLoading } from '../Elements/CustomButton';
import { sendRecoverCredentialsMail } from '../../services/mail/credentialsMail';
import { LinkForm } from '../Elements/LinkForm';
import { Bar } from '../Elements/CustomInput';
import { COLORS } from '../../assets/css/_colors';
import Error from '../Elements/Error';
import { LocalError } from '../../assets/css/_elements';
import { SpinnerBtn } from '../Elements/Spinner';
import {
    LoginForm as Form,
    LoginRow as Row,
    Element,
    Input,
    LabelContainer,
} from '../../assets/css/_forms';
import usernameIcon from '../../assets/images/login-user.svg';

export const RecoverCredentialsForm = (props) => {
    const { register, handleSubmit, errors, watch, setValue } = useForm();
    const history = useHistory();
    const [usernameCSS, setUsernameCSS] = useState({ top: '30px' });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [saveBtnStyle, setSaveBtnStyle] = useState({});

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            setSaveBtnStyle({
                backgroundColor: 'transparent',
                color: `${COLORS.green}`,
                border: `2px solid ${COLORS.green}`,
            });
            setError('');
            await sendRecoverCredentialsMail(data.username);
            history.push('/');
        } catch (e) {
            setError(e);
            setLoading(false);
            setValue('username', null);
            setSaveBtnStyle({
                backgroundColor: `${COLORS.green}`,
                color: 'white',
                border: `2px solid ${COLORS.green}`,
            });
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Element
                    width='100%'
                    onFocus={() => setUsernameCSS({ top: '0px' })}
                    onBlur={() =>
                        watch('username') === ''
                            ? setUsernameCSS({ top: '30px' })
                            : setUsernameCSS({ top: '0px' })
                    }
                >
                    <Input
                        autoComplete='off'
                        name='username'
                        type='text'
                        ref={register({
                            required: {
                                value: true,
                                message: 'Unesite korisničko ime',
                            },
                            maxLength: {
                                value: 25,
                                message:
                                    'Korisničko ime ne smije imati najviše 25 znakova',
                            },
                        })}
                        onChange={(e) => setError('')}
                    />
                    <Bar />
                    <LabelContainer style={usernameCSS}>
                        <img
                            style={{
                                transition: 'all 0.2s ease',
                                width: '15px',
                                marginRight: '10px',
                            }}
                            src={usernameIcon}
                            alt='login'
                        />
                        <span>Korisničko ime</span>
                    </LabelContainer>
                    {errors.username && (
                        <LocalError>{errors.username.message}</LocalError>
                    )}
                </Element>
            </Row>

            {error && <Error msg={error} style={{ padding: '0 10px' }} />}

            <ButtonLoading style={saveBtnStyle} type='submit'>
                {loading && <SpinnerBtn />}
                Pošalji
            </ButtonLoading>
            <LinkForm
                to={'/login'}
                text={'Imate račun?'}
                linkText={'Prijavite se!'}
            />
        </Form>
    );
};

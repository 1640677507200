import { API_URL } from '../constants/index';

export async function getShippingMethods(token, userId) {
    try {
        const res = await fetch(`${API_URL}/shippingMethods`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
                UserId: userId,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function getBillingMethods(token, userId) {
    try {
        const res = await fetch(`${API_URL}/billingMethods`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
                UserId: userId,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function getDeliveryMethods(token, userId) {
    try {
        const res = await fetch(`${API_URL}/deliveryMethods`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
                UserId: userId,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function getMasterStatusList(token, userId) {
    try {
        const res = await fetch(`${API_URL}/offerRequest/master/status-list`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
                UserId: userId,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

// export async function uploadFile(file) {
//     try {
//         console.log(file);
//         const res = await fetch(`${API_URL}/upload-file`, {
//             method: 'POST',
//             headers: {
//                 // Accept: 'application/json',
//                 'Content-Type': 'multipart/form-data',
//             },
//             body: file,
//         });
//         if (res.status === 200) {
//             const responseData = await res.json();
//             return responseData;
//         } else {
//             const json = await res.json();
//             throw json.Message;
//         }
//     } catch (e) {
//         throw e;
//     }
// }

import styled from 'styled-components';
import { COLORS } from '../css/_colors';

export const Wrapper = styled.div`
    min-height: 100vh;
    background: ${COLORS.grayLightBackground};
    position: relative;
    padding-bottom: 500px;
    padding-top: 70px;
    z-index: 1;

    @media (max-width: 767px) {
        padding-top: 0;
    }

    @media (max-width: 480px) {
        padding-bottom: 600px;
    }
`;

export const Container = styled.div`
    min-height: 100vh;
    max-width: 1000px;
    margin: 0 auto;
    transition: all 1s ease;
    padding: 0 40px;

    @media (max-width: 767px) {
        padding-top: 50px;
    }

    @media (max-width: 480px) {
        padding: 0 20px;
        padding-top: 50px;
    }
`;

import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { DataContext } from '../../../context/ArticleContext';
import { COLORS } from '../../../assets/css/_colors';
import { EditIcon, Bar } from '../../Elements/CustomInput';
import editIcon from '../../../assets/images/edit.svg';

const Textarea = styled.textarea`
    width: 100%;
    border: 0;
    border-bottom: 1.3px solid gray;
    display: block;
    outline: none;
    font-size: 17px;
    background-color: transparent !important;
    color: ${COLORS.grayTextDark};
    resize: none;

    &:focus ~ .bar::before,
    &:focus ~ .bar::after {
        width: 50%;
    }
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: ${(props) =>
        props.checkboxes ? 'repeat(6, auto)' : 'repeat(2, 50%)'};
    margin-top: 20px;

    @media (max-width: 767px) {
        grid-template-columns: repeat(2, 50%);
    }

    @media (max-width: 480px) {
        grid-template-columns: 100%;
    }
`;

const Element = styled.div`
    width: ${(props) => props.width};
    position: relative;
    padding: 15px 0;

    @media (max-width: 767px) {
        width: 100%;
    }
`;

const Error = styled.div`
    color: ${COLORS.red};
    font-size: 15px;
    border: 1px solid ${COLORS.red};
    padding: 0 30px;
    border-radius: 4px;
    display: inline-flex;
    transition: all 1s ease;
    width: 100%;

    p {
        margin-left: 10px;
    }

    @media (max-width: 767px) {
        padding: 0 20px;
        margin: 0 20px;
    }
`;

const RequestResponse = ({ showResponse, setError }) => {
    const { register, handleSubmit, setValue, errors, watch } = useForm();
    const { articleDescription, setArticleDescription } = useContext(
        DataContext
    );

    function onDescriptionChange(e) {
        setError('');
        setArticleDescription(e.target.value);
    }

    return (
        <div>
            {!showResponse && (
                <Row style={{ gridTemplateColumns: '100%' }}>
                    <Element width='100%'>
                        <p
                            style={{
                                marginTop: '0',
                                marginBottom: '10px',
                                color: `${COLORS.green}`,
                            }}
                        >
                            Upit za ponudom
                            <EditIcon
                                style={{ marginTop: '0' }}
                                src={editIcon}
                            />
                        </p>
                        <Textarea
                            autoComplete='off'
                            rows='5'
                            placeholder='Opišite upit za ponudom...'
                            name='description'
                            type='text'
                            value={articleDescription}
                            onChange={onDescriptionChange}
                            ref={register({
                                maxLength: {
                                    value: 512,
                                    message: 'Maksimalno 512 znakova',
                                },
                            })}
                        />
                        <Bar className='bar' />
                    </Element>
                    {errors.description && (
                        <Error description>{errors.description.message}</Error>
                    )}
                </Row>
            )}

            {showResponse && (
                <Row>
                    <Element width='95%'>
                        <p
                            style={{
                                marginTop: '0',
                                marginBottom: '10px',
                                color: `${COLORS.green}`,
                            }}
                        >
                            Upit za ponudom
                        </p>
                        <Textarea
                            autoComplete='off'
                            rows='5'
                            placeholder='Opišite upit za ponudom...'
                            name='description'
                            type='text'
                            value={articleDescription}
                            onChange={onDescriptionChange}
                            ref={register({
                                maxLength: {
                                    value: 512,
                                    message: 'Maksimalno 512 znakova',
                                },
                            })}
                        />
                        <Bar className='bar' />
                        {errors.description && (
                            <p style={{ color: `${COLORS.red}` }}>
                                {errors.description.message}
                            </p>
                        )}
                    </Element>

                    <Element width='95%'>
                        <p
                            style={{
                                marginTop: '0',
                                marginBottom: '10px',
                                color: `${COLORS.green}`,
                            }}
                        >
                            Odgovor
                        </p>
                        <Textarea
                            disabled
                            rows='5'
                            name='repairerResponse'
                            type='text'
                            ref={register}
                        />
                        <Bar className='bar' />
                    </Element>
                </Row>
            )}
        </div>
    );
};
export default RequestResponse;

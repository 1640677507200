import { API_URL } from '../../constants/index';

export async function contactUs(data) {
    try {
        const res = await fetch(`${API_URL}/send/mail/contact-us`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                UserId: data.UserId,
                Token: data.Token,
            },
            body: JSON.stringify({
                Name: data.Name,
                Email: data.Email,
                Subject: data.Subject,
                Message: data.Message,
            }),
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

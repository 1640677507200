import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
    getDetailInfo,
    registerDetail,
    updateDetail,
} from '../../services/offerRequest/detail';
import {
    registerMaster,
    updateMaster,
} from '../../services/offerRequest/master';
import SelectArticle from '../OfferRequest/ChoseArticles/SelectArticle';
import RequestResponse from '../OfferRequest/Detail/RequestResponse';
import Buttons from '../OfferRequest/Detail/Buttons';
import Error from '../Elements/Error';
import { DataContext } from '../../context/ArticleContext';
import { COLORS } from '../../assets/css/_colors';
import { DataContext as MasterDataContext } from '../../context/MasterDataContext';

const Form = styled.form`
    width: 100%;
    margin: 0;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: ${(props) =>
        props.checkboxes ? 'repeat(6, auto)' : 'repeat(2, 50%)'};
    margin-top: 20px;

    @media (max-width: 767px) {
        grid-template-columns: repeat(2, 50%);
    }

    @media (max-width: 480px) {
        grid-template-columns: 100%;
    }
`;

const Element = styled.div`
    width: ${(props) => props.width};
    position: relative;
    padding: 15px 0;

    @media (max-width: 767px) {
        width: 100%;
    }
`;

const DetailForm = ({ edit }) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = JSON.parse(localStorage.getItem('userId'));
    const { register, handleSubmit, setValue, errors, watch } = useForm();
    let { year, number, id, articleId, articlesVisited } = useParams();
    const history = useHistory();
    const [position, setPosition] = useState('');
    const [statusName, setStatusName] = useState('');
    const [articlesUrl, setArticlesUrl] = useState('');
    const [error, setError] = useState('');
    const [showResponse, setShowResponse] = useState(false);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const {
        selectedArticleId,
        setSelectedArticleId,
        articleQuantity,
        setArticleQuantity,
        articlePrice,
        offerPrice,
        articleName,
        articleMeasuringUnit,
        articleDescription,
        setArticleDescription,
    } = useContext(DataContext);

    const {
        address,
        deliveryAddress,
        deliveryMethodName,
        billingMethodName,
        currentBillingValue,
        currentDeliveryValue,

        currentClientValue,
        currentClientName,
    } = useContext(MasterDataContext);

    useEffect(() => {
        if (id) {
            fetchDetailInfo();
            setArticlesUrl(`/articles/edit/${year}/${number}/${id}`);
        } else {
            setValue('quantityInput', '1');
            setShow(true);
            setArticleQuantity(1);
            !year
                ? setArticlesUrl(`/articles/new/empty/empty`)
                : setArticlesUrl(`/articles/new/${year}/${number}`);

            if (articlesVisited !== 'true') setArticleDescription('');
        }
    }, []);

    const fetchDetailInfo = async () => {
        try {
            const data = await getDetailInfo(token, id);
            data.Response ? setShowResponse(true) : setShowResponse(false);
            setValue('repairerResponse', data.Response);
            setPosition(data.Position);
            setStatusName(data.StatusName);
            setShow(true);

            if (articlesVisited === 'true') {
                setArticleQuantity(1);
            } else {
                setArticleQuantity(data.RequiredQuantity);
                setArticleDescription(data.Description);
            }

            if (!articleId) {
                setSelectedArticleId(data.ArticleId);
                history.push(
                    `/edit/detail/${year}/${number}/${id}/${data.ArticleId}/false`
                );
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleUpdateDetail = async () => {
        try {
            var articleID;
            if (articleId === '' || articleId === 'empty') {
                articleID = null;
            } else {
                articleID = articleId;
            }
            var data = {
                MasterNumber: number,
                MasterYear: year,
                ArticleName: articleName,
                ArticleId: articleID,
                ArticlePrice: articlePrice,
                RequiredQuantity: articleQuantity,
                Price: offerPrice,
                MeasuringUnit: articleMeasuringUnit,
                Description: articleDescription,
            };
            // console.log(data);
            await updateDetail(token, id, data);
        } catch (e) {
            console.log(e);
        }
    };

    const handleUpdateMaster = async () => {
        try {
            let masterData = {
                Token: token,
                UserId: userId,
                Address: address,
                DeliveryAddress: deliveryAddress,
                DeliveryMethod: currentDeliveryValue,
                BillingMethod: currentBillingValue,
                BillingMethodName: billingMethodName,
                DeliveryMethodName: deliveryMethodName,
                ClientId: currentClientValue,
            };
            await updateMaster(masterData, year, number);
        } catch (e) {
            console.log(e);
        }
    };

    const handleRegisterMaster = async () => {
        try {
            const data = {
                Token: token,
                UserId: userId,
                Address: address,
                DeliveryAddress: deliveryAddress,
                DeliveryMethod: currentDeliveryValue,
                BillingMethod: currentBillingValue,
                BillingMethodName: billingMethodName,
                DeliveryMethodName: deliveryMethodName,
                ClientId: currentClientValue,
            };
            const response = await registerMaster(data);
            year = response.Year;
            number = response.Number;
        } catch (e) {
            console.log(e);
        }
    };

    const handleRegisterDetail = async () => {
        try {
            var articleID;
            if (articleId === '' || articleId === 'empty') {
                articleID = '';
            } else {
                articleID = articleId;
            }
            var data = {
                MasterNumber: number,
                MasterYear: year,
                ArticleName: articleName,
                ArticleId: articleID,
                ArticlePrice: articlePrice,
                RequiredQuantity: articleQuantity,
                Price: offerPrice,
                MeasuringUnit: articleMeasuringUnit,
                Description: articleDescription,
            };
            await registerDetail(token, data);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    const onSubmitForm = async () => {
        setLoading(true);
        try {
            if (!articleDescription && articleId === 'empty') {
                setError(
                    'Morate opisati upit za ponudom i/ili odabrati neki od dostupnih artikala!'
                );
                setLoading(false);
            } else {
                if (edit) {
                    await handleUpdateMaster();
                    await handleUpdateDetail();
                    localStorage.setItem(
                        'actionMessage',
                        'Upit je uspješno promijenjen!'
                    );
                    history.push(`/`);
                } else {
                    number === undefined
                        ? await handleRegisterMaster()
                        : await handleUpdateMaster();
                    await handleRegisterDetail();
                    localStorage.setItem(
                        'actionMessage',
                        'Upit je uspješno dodan!'
                    );
                    history.push(`/new/detail/${year}/${number}/empty/false`);
                    window.location.reload(false);
                }
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmitForm)}>
            {edit && (
                <Row>
                    <Element
                        width='95%'
                        style={{
                            borderBottom: `1px solid ${COLORS.grayLight}`,
                            color: `${COLORS.green}`,
                        }}
                    >
                        <label>Redni broj: </label>
                        <label style={{ color: `${COLORS.grayTextLight}` }}>
                            {position}
                        </label>
                    </Element>
                    <Element
                        width='95%'
                        style={{
                            justifySelf: 'end',
                            borderBottom: `1px solid ${COLORS.grayLight}`,
                            color: `${COLORS.green}`,
                        }}
                    >
                        <label>Status: </label>
                        <label style={{ color: `${COLORS.grayTextLight}` }}>
                            {statusName}
                        </label>
                    </Element>
                </Row>
            )}

            {show && (
                <SelectArticle
                    articleId={articleId}
                    articlesUrl={articlesUrl}
                    edit={true}
                />
            )}

            <RequestResponse
                showResponse={showResponse}
                setError={setError}
                articleId={articleId}
            />

            <Buttons edit={edit} id={id} loading={loading} />

            {error !== '' && <Error msg={error} />}
        </Form>
    );
};
export default DetailForm;

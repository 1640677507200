import React, { useState, useEffect } from 'react';
import MasterCard from './MasterCard';
import { getMasterListByStatusClient } from '../../../services/offerRequest/master';
import { EmptyState } from '../../Elements/EmptyState';
import { Spinner } from '../../Elements/Spinner';

const MasterList = ({ status, client, loading, setLoading }) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = JSON.parse(localStorage.getItem('userId'));
    const [masterList, setMasterList] = useState([]);

    useEffect(() => {
        fetchData();
    }, [status, client]);

    const fetchData = async () => {
        try {
            const data = await getMasterListByStatusClient(
                token,
                userId,
                status,
                client
            );
            setMasterList([]);
            data.forEach((element) => {
                var item = {
                    year: element.Year,
                    number: element.Number,
                    date: element.Date,
                    status: element.Status,
                    statusName: element.StatusName,
                    clientName: element.UserName,
                };
                setMasterList((prevState) => [...prevState, item]);
            });
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    return (
        <div>
            {loading ? (
                <Spinner />
            ) : (
                <div>
                    {masterList.length <= 0 && !loading && (
                        <EmptyState
                            msg={'Nije pronaden upit sa odabranim statusom!'}
                            styleImg={{ width: '200px' }}
                        />
                    )}
                    {masterList.map((master, index) => {
                        return (
                            <MasterCard
                                key={index}
                                master={master}
                                status={status}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default MasterList;

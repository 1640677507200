import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useAuth } from '../../context/AuthContext';
import { ButtonLoading } from '../Elements/CustomButton';
import { handleLogin } from '../../services/authorization/login';
import { SpinnerBtn } from '../Elements/Spinner';
import { LinkForm } from '../Elements/LinkForm';
import { Bar } from '../Elements/CustomInput';
import { COLORS } from '../../assets/css/_colors';
import Error from '../Elements/Error';
import { LocalError } from '../../assets/css/_elements';
import {
    LoginForm as Form,
    LoginRow as Row,
    Element,
    Input,
    LabelContainer,
} from '../../assets/css/_forms';
import loginUsernameIcon from '../../assets/images/login-user.svg';
import loginPasswordIcon from '../../assets/images/login-lock.svg';

export const LoginForm = (props) => {
    const { register, handleSubmit, setValue, errors, watch } = useForm();
    const authStore = useAuth();
    const history = useHistory();
    const [usernameCSS, setUsernameCSS] = useState({ top: '30px' });
    const [passwordCSS, setPasswordCSS] = useState({ top: '30px' });
    const [saveBtnStyle, setSaveBtnStyle] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const onSubmit = async (data) => {
        setError('');
        try {
            setLoading(true);
            setSaveBtnStyle({
                backgroundColor: 'transparent',
                color: `${COLORS.green}`,
                border: `2px solid ${COLORS.green}`,
                paddingTop: '0',
            });
            const userData = await handleLogin(data.username, data.password);
            authStore.login(userData.Id, userData.Token);
            history.push('/');
        } catch (e) {
            setError(e.Message);
            setValue('password', '');
            setLoading(false);
            setSaveBtnStyle({
                backgroundColor: `${COLORS.green}`,
                color: 'white',
                border: `2px solid ${COLORS.green}`,
            });
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '0' }}>
            <Row>
                <Element
                    width='100%'
                    onFocus={() => setUsernameCSS({ top: '0px' })}
                    onBlur={() =>
                        watch('username') === ''
                            ? setUsernameCSS({ top: '30px' })
                            : setUsernameCSS({ top: '0px' })
                    }
                >
                    <Input
                        autoComplete='off'
                        name='username'
                        type='text'
                        ref={register({
                            required: {
                                value: true,
                                message: 'Unesite korisničko ime',
                            },
                        })}
                        onChange={(e) => setError('')}
                        autoComplete='off'
                    />
                    <Bar />
                    <LabelContainer style={usernameCSS}>
                        <img
                            style={{
                                transition: 'all 0.2s ease',
                                width: '15px',
                                marginRight: '10px',
                            }}
                            src={loginUsernameIcon}
                            alt='login'
                        />
                        <span>Korisničko ime</span>
                    </LabelContainer>
                    {errors.username && errors.username.type === 'required' && (
                        <LocalError>Unesite korisničko ime</LocalError>
                    )}
                </Element>
            </Row>

            <Row>
                <Element
                    width='100%'
                    onFocus={() => setPasswordCSS({ top: '0px' })}
                    onBlur={() =>
                        watch('password') === ''
                            ? setPasswordCSS({ top: '30px' })
                            : setPasswordCSS({ top: '0px' })
                    }
                >
                    <Input
                        ref={register({
                            required: {
                                value: true,
                                message: 'Unesite lozinku',
                            },
                        })}
                        aria-invalid={errors.password ? 'true' : 'false'}
                        autoComplete='off'
                        name='password'
                        type='password'
                        onChange={(e) => setError('')}
                    />
                    <Bar />
                    <LabelContainer style={passwordCSS}>
                        <img
                            style={{
                                transition: 'all 0.2s ease',
                                width: '15px',
                                marginRight: '10px',
                            }}
                            src={loginPasswordIcon}
                            alt='login'
                        />
                        <span>Lozinka</span>
                    </LabelContainer>
                    {errors.password && errors.password.type === 'required' && (
                        <LocalError>Unesite lozinku</LocalError>
                    )}
                </Element>
            </Row>

            {error && <Error msg={error} style={{ padding: '0 10px' }} />}

            <ButtonLoading
                style={saveBtnStyle}
                green
                type='submit'
                onClick={(e) => setError('')}
            >
                {loading && <SpinnerBtn />}
                Prijavi
            </ButtonLoading>

            <LinkForm
                to={'/register'}
                text={'Niste registrirani?'}
                linkText={'Kreiraj račun!'}
            />
        </Form>
    );
};

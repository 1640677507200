import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../assets/css/_colors';

const Wrapper = styled.div`
    text-align: center;
    margin-bottom: 50px;
`;

const Link = styled.a`
    text-decoration: none;
    color: ${COLORS.grayLight};
    margin-right: 20px;
    transition: all 0.5s ease;

    :hover {
        color: ${COLORS.grayLightBackground};
    }

    @media (max-width: 480px) {
        margin-right: 0;
    }
`;

const Container = styled.ul`
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0;

    li {
        display: inline;
    }

    @media (max-width: 480px) {
        li {
            display: block;
            margin-bottom: 5px;
        }
    }
`;

const Links = (props) => {
    return (
        <Wrapper>
            <h3 style={{ textAlign: 'center' }}>SITEMAP</h3>
            <Container>
                <li>
                    <Link href='http://www.infodata.hr/index.html'>
                        Početna
                    </Link>
                </li>
                <li>
                    <Link href='http://www.infodata.hr/racunala.php'>
                        Servis
                    </Link>
                </li>
                <li>
                    <Link href='http://www.infodata.hr/3dscan.php'>
                        3D Scan
                    </Link>
                </li>
                <li>
                    <Link href='http://www.infodata.hr/3dprint.php'>
                        3D Print
                    </Link>
                </li>
                <li>
                    <Link href='http://www.infodata.hr/kontakt.php'>
                        Kontakt
                    </Link>
                </li>
                <li>
                    <Link href='http://www.infodata.hr/o_nama.php'>O nama</Link>
                </li>
            </Container>
        </Wrapper>
    );
};

export default Links;

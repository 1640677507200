import styled from 'styled-components';
import { COLORS } from './_colors';

export const Title = styled.h2`
    padding: 0;
    padding-top: 70px;
    margin: 0;
    color: ${COLORS.grayTextLight};

    @media (max-width: 767px) {
        padding-top: 50px;
    }
`;

export const LocalError = styled.p`
    color: ${COLORS.red} !important;
    font-size: 13px;
    margin-bottom: 0;
`;

import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import DetailForm from '../../Forms/DetailForm';
import MasterInfoNew from '../../OfferRequest/Master/MasterInfoNew';
import MasterInfoSaved from '../../OfferRequest/Master/MasterInfoSaved';
import Warning from '../../Elements/Warning';
import Footer from '../../Footer/Footer';
import { Title } from '../../../assets/css/_elements';
import { Wrapper, Container } from '../../../assets/css/_pages';
import { getUserInfo } from '../../../services/user/info';
import { getMasterInfo } from '../../../services/offerRequest/master';
import { Spinner } from '../../Elements/Spinner';
import { DefaultInfo } from '../../Elements/DefaultInfo';
import { DataContext } from '../../../context/MasterDataContext';
import { Notification } from '../../Elements/Notification';
import { ReactComponent as ArrowNext } from '../../../assets/images/arrow_nextTitle.svg';

const DetailNewPage = (props) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = JSON.parse(localStorage.getItem('userId'));
    const { year, number, articlesVisited } = useParams();
    const [userType, setUserType] = useState('');
    const [status, setStatus] = useState('');
    const [date, setDate] = useState('');
    const [user, setUser] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [firmName, setFirmName] = useState('');
    const [loading, setLoading] = useState(true);
    const {
        setAddress,
        setDeliveryAddress,
        setDeliveryMethodName,
        setBillingMethodName,
        setCurrentBillingValue,
        setCurrentDeliveryValue,
        setEdit,

        setCurrentClientValue,
    } = useContext(DataContext);

    useEffect(() => {
        !isNaN(year) && !isNaN(number) ? fetchMasterData() : fetchUserData();
    }, []);

    useEffect(() => {
        setEdit(false);

        if (articlesVisited !== 'true') {
            setCurrentBillingValue('01');
            setCurrentDeliveryValue(1);
            setDeliveryMethodName('OSOBNO DONIJETI U FIRMU');
            setBillingMethodName('VIRMANI');
        }
    }, [articlesVisited]);

    const fetchMasterData = async () => {
        try {
            const data = await getMasterInfo(token, year, number);
            setStatus(data.Status);
            setDate(data.Date);
            setDeliveryMethodName(data.DeliveryMethodName);
            setBillingMethodName(data.BillingMethodName);
            setCurrentBillingValue(data.BillingMethod);
            setCurrentDeliveryValue(data.DeliveryMethod);
            setCurrentClientValue(data.ClientId);

            if (articlesVisited !== 'true') {
                setAddress(data.Address);
                setDeliveryAddress(data.DeliveryAddress);
            }

            const userData = await getUserInfo(token, userId);
            setFirmName(userData.FirmName);
            setPhone(userData.Phone);
            setEmail(userData.Email);
            setUser(userData.FirstName + ' ' + userData.LastName);
            setUserType(data.UserType);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchUserData = async () => {
        try {
            const data = await getUserInfo(token, userId);
            setFirmName(data.FirmName);
            setPhone(data.Phone);
            setEmail(data.Email);
            setUser(data.FirstName + ' ' + data.LastName);
            setUserType(data.Type);
            if (articlesVisited !== 'true') {
                if (data.Address !== '') {
                    setAddress(
                        data.Address + ', ' + data.ZipCode + ', ' + data.City
                    );
                }
                if (data.DeliveryAddress !== '') {
                    setDeliveryAddress(
                        data.DeliveryAddress +
                            ', ' +
                            data.DeliveryZipCode +
                            ', ' +
                            data.DeliveryCity
                    );
                } else if (data.DeliveryAddress === '' && data.Address !== '') {
                    setDeliveryAddress(
                        data.Address + ', ' + data.ZipCode + ', ' + data.City
                    );
                }
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Wrapper>
            <Notification />
            <DefaultInfo />
            <Container>
                {loading ? (
                    <Spinner style={{ marginTop: '100px' }} />
                ) : (
                    <div>
                        <div
                            style={{ display: 'flex', alignItems: 'flex-end' }}
                        >
                            <ArrowNext style={{ marginRight: '20px' }} />
                            <Title>Upit za ponudom</Title>
                        </div>

                        {year && number && (
                            <MasterInfoSaved
                                date={date}
                                status={status}
                                user={user}
                                firmName={firmName}
                                userType={userType}
                                phone={phone}
                                email={email}
                            />
                        )}
                        {!year && !number && (
                            <MasterInfoNew
                                user={user}
                                firmName={firmName}
                                userType={userType}
                                phone={phone}
                                email={email}
                            />
                        )}
                        <Warning
                            msg={
                                'Ako promijenite neke od podataka vezanih uz upit, oni će se promijeniti za sve stavke tog upita!'
                            }
                        />
                        <div
                            style={{ display: 'flex', alignItems: 'flex-end' }}
                        >
                            <ArrowNext style={{ marginRight: '20px' }} />
                            <Title>Stavka upita za ponudom</Title>
                        </div>
                        <DetailForm edit={false} />
                    </div>
                )}
            </Container>
            <Footer />
        </Wrapper>
    );
};

export default DetailNewPage;

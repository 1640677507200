import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import { getUserInfo } from '../../services/user/info';
import { DialogWarning } from '../Elements/Dialog';
import { COLORS } from '../../assets/css/_colors';
import logo from '../../assets/images/infodata.png';
import paperPlane from '../../assets/images/paper-plane.svg';
import logout from '../../assets/images/log-out.svg';

const Wrapper = styled.div`
    @media (max-width: 767px) {
        display: none;
    }
`;

const Container = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 70% auto;
    position: fixed;
    top: 0;
    z-index: 100;
    background-color: ${COLORS.grayDarkBackground};
    height: 70px !important;
`;

const Links = styled.div`
    margin-left: 20px;
    font-size: 17px;
    display: inline-flex;
`;

const Logout = styled.div`
    color: gray;
    font-size: 17px;
    display: inline-flex;
    justify-content: flex-end;
    margin-right: 10px;

    Link {
        vertical-align: middle;
        margin: auto 0;
    }
`;

const LogoutBtn = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
        color: black;
    }

    :active,
    :visited,
    :focus {
        border: none;
    }
`;

const ContactUs = styled.button`
    cursor: pointer;
    background-color: ${COLORS.green};
    border: none;
    padding: 10px 25px;
    padding-left: 10px;
    font-size: 17px;
    border-radius: 5px;
    color: white;
    transition: all 0.5s ease;
    margin-left: 10px;

    :hover {
        opacity: 0.8;
    }
`;

const CustomButton = styled.button`
    color: ${COLORS.grayLight};
    margin-right: 20px;
    background-color: transparent;
    border: none;
    font-size: 17px;
    cursor: pointer;
    transition: 0.5s all ease;
    width: auto;

    :hover {
        color: white;
    }
`;

const Navbar = (props) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = JSON.parse(localStorage.getItem('userId'));
    const history = useHistory();
    const [linkMarkup, setLinkMarkup] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [name, setName] = useState('');
    const links = [
        {
            label: 'Početna',
            link: '/',
        },
        {
            label: 'Dodaj upit',
            link: '/new/detail/empty/false',
        },
        {
            label: 'Lokacije',
            link: '/client/list',
        },
    ];

    useEffect(() => {
        const url = window.location.pathname.slice(15);
        setLinkMarkup(
            links.map((link, index) => {
                if (url === link.link) {
                    return (
                        <div key={index}>
                            <CustomButton
                                type='button'
                                style={{
                                    borderBottom: '1px solid white',
                                }}
                                onClick={(e) => history.push(link.link)}
                            >
                                {link.label}
                            </CustomButton>
                        </div>
                    );
                } else {
                    return (
                        <div key={index}>
                            <CustomButton
                                type='button'
                                onClick={(e) => history.push(link.link)}
                            >
                                {link.label}
                            </CustomButton>
                        </div>
                    );
                }
            })
        );

        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const data = await getUserInfo(token, userId);
            setName(data.FirstName + ' ' + data.LastName);
        } catch (e) {
            console.log(e);
            history.push('/login');
        }
    };

    return (
        <Wrapper>
            <Container>
                <div
                    style={{
                        marginLeft: '20px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {/* <Link to={'/'}>
                        <img
                            style={{
                                width: '70px',
                                height: '25px',
                            }}
                            src={logo}
                            alt='infodata'
                        />
                    </Link> */}
                    <Links>{linkMarkup}</Links>
                    <ContactUs onClick={() => history.push('/contact-us')}>
                        <img
                            style={{ width: '15px', marginRight: '10px' }}
                            src={paperPlane}
                            alt='infodata'
                        />
                        Kontakt
                    </ContactUs>
                </div>
                <Logout>
                    <CustomButton
                        type='button'
                        style={{ marginRight: '0' }}
                        onClick={(e) => history.push('/profile')}
                    >
                        {name} /
                    </CustomButton>
                    <LogoutBtn onClick={(e) => setOpen(true)}>
                        <img
                            style={{ width: '30px', marginLeft: '10px' }}
                            src={logout}
                            alt='infodata'
                        />
                    </LogoutBtn>
                </Logout>
            </Container>

            <DialogWarning
                open={open}
                onClose={() => setOpen(false)}
                title={'Upozorenje!'}
                message={'Jeste li sigurni da se želite odjaviti?'}
                onClickCancel={() => setOpen(false)}
                onClickOk={() => history.push('/login')}
                cancelText={'odustani'}
                okText={'Odjava'}
            />
        </Wrapper>
    );
};

export default Navbar;

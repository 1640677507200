import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../assets/css/_colors';
import { SelectedGroupContext } from '../../../context/WebShopContext';
import { getArticleGroupList } from '../../../services/offerRequest/article';
import CustomSelect from '../../Elements/CustomSelect';

const Container = styled.div`
    .mobile {
        display: none;
    }

    @media (max-width: 1100px) {
        .mobile {
            display: block;
            width: 60%;
        }
        .desktop {
            display: none;
        }
    }

    @media (max-width: 767px) {
        .mobile {
            width: 100%;
        }
    }

    @media (max-width: 480px) {
        .mobile {
            width: 100%;
        }
    }
`;

const Group = styled.button`
    width: 250px;
    text-transform: uppercase;
    background-color: ${COLORS.greenLight};
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    color: white;
    transition: 0.5s all ease;
    border-bottom: 0.5px solid ${COLORS.grayLightBackground};

    :hover {
        background-color: green;
    }
`;

const Header = styled.div`
    width: 250px;
    text-transform: uppercase;
    background-color: ${COLORS.grayDark};
    padding: 20px 20px;
    border: none;
    color: white;
    text-align: center;
`;

const Groups = ({ setSearchValue, setFirstPage }) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = JSON.parse(localStorage.getItem('userId'));
    const [groups, setGroups] = useState([]);
    const [options, setOptions] = useState([]);
    const [
        selectedGroup,
        setSelectedGroup,
        selectedGroupName,
        setSelectedGroupName,
    ] = useContext(SelectedGroupContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getArticleGroupList(token, userId);
                response.forEach((element) => {
                    var item = {
                        value: element.Id,
                        label: element.Name,
                    };
                    setOptions((prevState) => [...prevState, item]);
                });
                setGroups(response);
            } catch (e) {
                console.log(e);
            }
        };

        fetchData();
    }, []);

    const onSelectChange = async (e) => {
        if (e !== null) {
            setFirstPage();
            setSearchValue('');
            setSelectedGroup(e.value);
            setSelectedGroupName(e.label);
        }
    };

    return (
        <Container>
            <div className='desktop'>
                <Header>sve kategorije</Header>
                {groups.map((group, index) => {
                    if (group.Id === selectedGroup) {
                        return (
                            <Group
                                onClick={(e) => (
                                    setFirstPage(),
                                    setSearchValue(''),
                                    setSelectedGroup(group.Id),
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth',
                                    })
                                )}
                                style={{ background: 'green' }}
                                key={index}
                            >
                                {group.Name}
                            </Group>
                        );
                    } else {
                        return (
                            <Group
                                onClick={(e) => (
                                    setFirstPage(),
                                    setSearchValue(''),
                                    setSelectedGroup(group.Id),
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth',
                                    })
                                )}
                                key={index}
                            >
                                {group.Name}
                            </Group>
                        );
                    }
                })}
            </div>
            <div className='mobile'>
                <CustomSelect
                    options={options}
                    onChange={onSelectChange}
                    placeholder={'Odaberite grupu...'}
                    defaultValue={{
                        value: selectedGroup,
                        label: selectedGroupName,
                    }}
                />
            </div>
        </Container>
    );
};

export default Groups;

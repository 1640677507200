import React from 'react';
import styled from 'styled-components';
import { ButtonBasic } from '../../Elements/CustomButton';
import { useHistory } from 'react-router-dom';
import { DialogWarning } from '../../Elements/Dialog';
import { cancelDetail } from '../../../services/offerRequest/detail';
import { SpinnerSmall } from '../../Elements/Spinner';
import deleteIcon from '../../../assets/images/delete.svg';
import saveIcon from '../../../assets/images/save.svg';
import cancelIcon from '../../../assets/images/cancel.svg';

const Container = styled.div`
    display: flex;
    padding: 20px 0;

    @media (max-width: 767px) {
        padding: 20px 0;
    }

    @media (max-width: 480px) {
        display: grid;
        grid-template-columns: repeat(2, 50%);
    }
`;

const Buttons = ({ edit, id, loading }) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const [open, setOpen] = React.useState(false);
    const history = useHistory();

    const onDeleteClick = async () => {
        try {
            await cancelDetail(token, id);
            history.push(`/`);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Container>
            {edit ? (
                <ButtonBasic
                    style={{
                        marginRight: '10px',
                        width: 'auto',
                        display: 'inline-flex',
                    }}
                    type='button'
                    onClick={() => setOpen(true)}
                >
                    <img src={deleteIcon} />
                    <span
                        style={{
                            marginLeft: '10px',
                            alignSelf: 'center',
                        }}
                    >
                        Obriši
                    </span>
                </ButtonBasic>
            ) : (
                <ButtonBasic
                    style={{
                        marginRight: '10px',
                        width: 'auto',
                        display: 'inline-flex',
                    }}
                    type='button'
                    onClick={() => history.push(`/`)}
                >
                    <img src={cancelIcon} />
                    <span
                        style={{
                            marginLeft: '10px',
                            alignSelf: 'center',
                        }}
                    >
                        Odustani
                    </span>
                </ButtonBasic>
            )}

            <ButtonBasic
                style={{
                    width: 'auto',
                    display: 'inline-flex',
                }}
                type='submit'
            >
                <img src={saveIcon} />
                <span
                    style={{
                        marginLeft: '10px',
                        alignSelf: 'center',
                    }}
                >
                    Spremi
                </span>
            </ButtonBasic>

            {loading && <SpinnerSmall />}

            <DialogWarning
                open={open}
                onClose={() => setOpen(false)}
                title={'Upozorenje!'}
                message={'Jeste sigurni da želite obrisati upit za ponudom?'}
                onClickCancel={() => setOpen(false)}
                onClickOk={onDeleteClick}
                cancelText={'odustani'}
                okText={'obriši'}
            />
        </Container>
    );
};
export default Buttons;

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Dots } from '../../Elements/Dots';
import { COLORS } from '../../../assets/css/_colors';

const Wrapper = styled.div`
    max-width: 1000px;
    margin: 20px 0;
    padding: 20px 20px;
    cursor: pointer;
    background-color: #fff;
    transition: all 0.3s ease;
    height: auto;
    display: grid;
    grid-template-columns: 5% 90% 5%;

    &:hover {
        border-radius: 5px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2),
            0 4px 20px 0px rgba(0, 0, 0, 0.19);
    }

    @media (max-width: 767px) {
    }
`;

const Label = styled.label`
    text-align: center;
    color: ${(props) => (props.labelValue ? 'black' : `${COLORS.green}`)};
    grid-area: ${(props) => props.gridArea};

    @media (max-width: 767px) {
        /* text-align: start; */
    }
`;

const Position = styled.data`
    width: 95%;
    position: relative;
    height: auto;
    margin: auto 0;
    overflow-wrap: break-word;
    color: ${COLORS.green};

    @media (max-width: 767px) {
        margin: ${(props) => (props.middle ? '20px 0' : '0')};
        margin: auto;
    }
`;

const Data = styled.div`
    height: auto;
    color: ${COLORS.green};
    overflow-wrap: break-word;
    display: grid;
    grid-template-columns: 40% auto 10% 20%;
    grid-template-rows: 2;

    @media (max-width: 767px) {
        grid-template-columns: 100%;

        .firstRow {
            margin-top: 0;
        }
        .quantity {
            display: none;
        }
    }
`;

const Element = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 30px auto;
    padding: 10px 5px;
    border-right: 1px solid ${COLORS.grayBorderLight};

    @media (max-width: 767px) {
        grid-template-rows: 20px auto;
        border: none;
        margin-top: 20px;
        padding: 0 5px;
    }
`;

const DetailCard = ({ detail, edit }) => {
    let history = useHistory();

    useEffect(() => {}, []);

    function onCardClick(e) {
        if (edit) {
            history.push(
                `/edit/detail/${detail.year}/${detail.number}/${detail.id}/${detail.articleId}/false`
            );
        } else {
            history.push(
                `/detail/${detail.year}/${detail.number}/${detail.id}`
            );
        }
    }

    return (
        <Wrapper onClick={onCardClick}>
            <Position>
                <label style={{ color: 'black' }}>{detail.position}.</label>
            </Position>

            <Data>
                <Element className='firstRow'>
                    <Label>NAZIV ARTIKLA</Label>
                    <Label labelValue>
                        {!detail.articleName && '-'}
                        {detail.articleName}
                    </Label>
                </Element>
                <Element>
                    <Label>OPIS ARTIKLA</Label>
                    <Label labelValue>
                        {!detail.description && '-'}
                        {detail.description}
                    </Label>
                </Element>
                <Element className='quantity'>
                    <Label>KOL.</Label>
                    <Label labelValue>{detail.quantity}</Label>
                </Element>
                <Element style={{ border: 'none' }}>
                    <Label>STATUS</Label>
                    <Label labelValue>
                        {!detail.statusName && '-'}
                        {detail.statusName}
                    </Label>
                </Element>
            </Data>

            <Dots />
        </Wrapper>
    );
};

export default DetailCard;

import React, { useEffect } from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { store } from 'react-notifications-component';
import { NotificationCard } from './NotificationCard';
import 'animate.css';

export const Notification = (props) => {
    const actionMessage = localStorage.getItem('actionMessage');

    useEffect(() => {
        setTimeout(() => {
            localStorage.setItem('actionMessage', '');
        }, 3500);
        if (actionMessage !== '') showNotification();
    }, []);

    function myF() {
        localStorage.setItem('actionMessage', '');
    }

    const showNotification = () => {
        store.addNotification({
            content: NotificationCard,
            type: 'success',
            className: 'notification__custom--success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
                duration: 3000,
                showIcon: true,
                pauseOnHover: true,
            },
        });
    };

    return <ReactNotification onClick={myF} />;
};

import React, { useState } from 'react';
import { ButtonBasic } from '../../Elements/CustomButton';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import { COLORS } from '../../../assets/css/_colors';
import Slider from '@material-ui/core/Slider';

const Filter = styled.div`
    align-items: center;
    margin: 50px auto;
    max-width: 500px;

    .MuiSlider-rail,
    .MuiSlider-track,
    .MuiSlider-root,
    .MuiSlider-thumb {
        height: 10px;
    }

    .MuiSlider-root {
        vertical-align: middle;
    }

    .MuiSlider-rail {
        border-radius: 5px;
        background-color: ${COLORS.grayLight};
    }

    .MuiSlider-thumb {
        margin-top: 0;
        width: 10px;
        border: 1px solid green;
        background-color: white;
    }
`;

const FilterBottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
        align-items: center;
        span {
            color: ${COLORS.grayTextLight};
        }
    }

    span {
        color: ${COLORS.grayTextLight};
    }
`;

const PriceFilter = ({
    defaultPrice,
    setPriceRange,
    setIsFilteredByPrice,
    setShowFilterByPrice,
}) => {
    const [range, setRange] = useState([defaultPrice[0], defaultPrice[1]]);
    const onRangeChange = (event, newValue) => {
        setRange(newValue);
    };

    const onFilterClick = () => {
        setIsFilteredByPrice(true);
        setPriceRange(range);
    };

    const onDeleteFilter = () => {
        setShowFilterByPrice(false);
        setIsFilteredByPrice(false);
        setRange([defaultPrice[0], defaultPrice[1]]);
        setPriceRange([defaultPrice[0], defaultPrice[1]]);
    };

    return (
        <Filter>
            <Slider
                style={{ color: 'green' }}
                min={defaultPrice[0]}
                max={defaultPrice[1]}
                value={range}
                onChange={onRangeChange}
            />
            <FilterBottom>
                <ButtonBasic type='button' onClick={onFilterClick}>
                    filtriraj
                </ButtonBasic>

                <span>
                    Cijena: {range[0]} kn - {range[1]} kn
                </span>
                <button
                    style={{
                        background: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={onDeleteFilter}
                >
                    <CloseIcon width='25px' fill={`${COLORS.grayTextLight}`} />
                </button>
            </FilterBottom>
        </Filter>
    );
};

export default PriceFilter;

import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import MasterInfoSaved from '../../OfferRequest/Master/MasterInfoSaved';
import Footer from '../../Footer/Footer';
import DetailForm from '../../Forms/DetailForm';
import Warning from '../../Elements/Warning';
import { Title } from '../../../assets/css/_elements';
import { Wrapper, Container } from '../../../assets/css/_pages';
import { DefaultInfo } from '../../Elements/DefaultInfo';
import { getUserInfo } from '../../../services/user/info';
import { getMasterInfo } from '../../../services/offerRequest/master';
import { Spinner } from '../../Elements/Spinner';
import { DataContext } from '../../../context/MasterDataContext';
import { ReactComponent as ArrowNext } from '../../../assets/images/arrow_nextTitle.svg';

const DetailEditPage = (props) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = JSON.parse(localStorage.getItem('userId'));
    let { year, number, articlesVisited } = useParams();
    const [userType, setUserType] = useState('');
    const [status, setStatus] = useState('');
    const [date, setDate] = useState('');
    const [user, setUser] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [firmName, setFirmName] = useState('');
    const [loading, setLoading] = useState(true);

    const {
        setAddress,
        setDeliveryAddress,
        setDeliveryMethodName,
        setBillingMethodName,
        setCurrentBillingValue,
        setCurrentDeliveryValue,
        currentClientValue,
        setCurrentClientValue,
        currentClientName,
        setCurrentClientName,
        edit,
        setEdit,
    } = useContext(DataContext);

    useEffect(() => {
        fetchData();
    }, []);

    // useEffect(() => {
    //     console.log(address, deliveryAddress);
    // }, [address, deliveryAddress]);

    const fetchData = async () => {
        try {
            const data = await getMasterInfo(token, year, number);
            setStatus(data.Status);
            setDate(data.Date);
            setUserType(data.UserType);
            data.Status === 0 ? setEdit(true) : setEdit(false);

            if (articlesVisited !== 'true') {
                setAddress(data.Address);
                setDeliveryAddress(data.DeliveryAddress);
                setDeliveryMethodName(data.DeliveryMethodName);
                setBillingMethodName(data.BillingMethodName);
                setCurrentBillingValue(data.BillingMethod);
                setCurrentDeliveryValue(data.DeliveryMethod);
                setCurrentClientValue(data.ClientId);
            }

            const userData = await getUserInfo(token, userId);
            setFirmName(userData.FirmName);
            setPhone(userData.Phone);
            setEmail(userData.Email);
            setUser(userData.FirstName + ' ' + userData.LastName);
            setUserType(data.UserType);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Wrapper>
            <DefaultInfo />
            <Container>
                {loading ? (
                    <Spinner style={{ marginTop: '100px' }} />
                ) : (
                    <div>
                        <div
                            style={{ display: 'flex', alignItems: 'flex-end' }}
                        >
                            <ArrowNext style={{ marginRight: '20px' }} />
                            <Title>Upit za ponudom</Title>
                        </div>

                        <MasterInfoSaved
                            date={date}
                            status={status}
                            user={user}
                            firmName={firmName}
                            userType={userType}
                            phone={phone}
                            email={email}
                        />
                        <Warning
                            msg={
                                'Ukoliko promijenite neke od podataka vezanih uz upit za ponudom, oni će se promijeniti za sve stavke tog upita!'
                            }
                        />
                        <div
                            style={{ display: 'flex', alignItems: 'flex-end' }}
                        >
                            <ArrowNext style={{ marginRight: '20px' }} />
                            <Title>Stavka upita za ponudom</Title>
                        </div>
                        <DetailForm edit={true} />
                    </div>
                )}
            </Container>
            <Footer />
        </Wrapper>
    );
};

export default DetailEditPage;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import SelectedArticle from '../ChoseArticles/SelectedArticle';
import { COLORS } from '../../../assets/css/_colors';

const Container = styled.div`
    width: 100%;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 50%);
    margin: 15px 0;

    @media (max-width: 767px) {
        grid-template-columns: 100%;
    }
`;

const Element = styled.data`
    width: ${(props) => props.width};
    position: relative;
    padding: 15px 0;
    border-bottom: 2px solid ${COLORS.grayDarkBackground};
    color: ${COLORS.green};

    @media (max-width: 767px) {
        width: 100%;
    }

    @media (max-width: 480px) {
        width: 100%;
    }
`;

const Textarea = styled.textarea`
    width: 100%;
    border: 0;
    border-bottom: 1.3px solid ${COLORS.grayDark};
    display: block;
    outline: none;
    font-size: 15px;
    background-color: transparent !important;
    color: ${COLORS.grayTextDark};
    resize: none;
    font-size: 17px;

    &:focus ~ .bar::before,
    &:focus ~ .bar::after {
        width: 50%;
    }
`;

const DetailInfoSaved = ({
    workOrder,
    position,
    statusName,
    articleId,
    articleName,
    articlePrice,
    articleQuantity,
    measuringUnit,
    price,
    description,
    response,
}) => {
    useEffect(() => {}, []);

    return (
        <Container>
            <Row>
                <Element
                    width='95%'
                    style={{
                        borderBottom: `1px solid ${COLORS.grayLight}`,
                    }}
                >
                    <label>Redni broj: </label>
                    <label style={{ color: `${COLORS.grayTextLight}` }}>
                        {position}
                    </label>
                </Element>
                <Element
                    width='95%'
                    style={{
                        justifySelf: 'end',
                        borderBottom: `1px solid ${COLORS.grayLight}`,
                    }}
                >
                    <label>Status: </label>
                    <label style={{ color: `${COLORS.grayTextLight}` }}>
                        {statusName}
                    </label>
                </Element>
            </Row>

            {articleId && (
                <SelectedArticle
                    articleName={articleName}
                    articlePrice={articlePrice}
                    articleQuantity={articleQuantity}
                    measuringUnit={measuringUnit}
                    price={price}
                />
            )}

            <Row style={{ paddingTop: '30px' }}>
                <Element width='95%' style={{ border: 'none' }}>
                    <p
                        style={{
                            marginTop: '0',
                            marginBottom: '10px',
                            color: `${COLORS.green}`,
                        }}
                    >
                        Upit za ponudom
                    </p>
                    <Textarea
                        disabled
                        rows='5'
                        placeholder='Niste opisali upit za ponudom...'
                        name='description'
                        type='text'
                        value={description}
                    />
                </Element>

                {response && (
                    <Element
                        width='95%'
                        style={{ border: 'none', justifySelf: 'end' }}
                    >
                        <p
                            style={{
                                marginTop: '0',
                                marginBottom: '10px',
                                color: `${COLORS.green}`,
                            }}
                        >
                            Odgovor
                        </p>
                        <Textarea
                            disabled
                            rows='5'
                            name='repairerResponse'
                            type='text'
                            value={response}
                        />
                    </Element>
                )}
            </Row>
        </Container>
    );
};

export default DetailInfoSaved;

import React from 'react';
import styled from 'styled-components';
import emptyStateIcon from '../../assets/images/empty-folder.svg';

const Container = styled.div`
    text-align: center;
    margin: 50px 0;

    img {
        width: 100px;
        margin: auto;
    }
`;

export const EmptyState = ({ msg, style, styleImg }) => {
    return (
        <Container style={style}>
            <img style={styleImg} src={emptyStateIcon} />
            <p>{msg}</p>
        </Container>
    );
};

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../assets/css/_colors';
import { ReactComponent as SearchIcon } from '../../../assets/images/search.svg';
import { ReactComponent as XIcon } from '../../../assets/images/cancel_search.svg';
import CustomSelect from '../../Elements/CustomSelect';
import { sort } from '../../../utils/sort';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 60% auto;
    /* margin: 0 5px; */

    .searchBar {
        position: relative;
        input {
            border-radius: 4px;
            border: 1px solid ${COLORS.grayLight};
            font-size: 15px;
            padding: 10px;
            padding-left: 40px;
            width: 100%;
            color: ${COLORS.grayTextDark};
        }

        .search {
            position: absolute;
            top: 7px;
            left: 10px;
        }
    }

    .sort {
        width: 170px;
        margin-left: 10px;
    }

    @media (max-width: 767px) {
        .sort {
            justify-self: end;
            margin-left: 0;
            width: 95%;
        }
    }

    @media (max-width: 480px) {
        grid-template-columns: 100%;

        .sort {
            margin-top: 10px;
            margin-left: 0;
            width: 100%;
        }
    }
`;

const ClearBtn = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: 1s all ease;
`;

const SearchAndSortArticles = ({
    searchValue,
    setSearchValue,
    setSelectedSort,
    selectedSort,
    filteredArticles,
    setFilteredArticles,
    showArticleList,
    setCurrentPage,
}) => {
    const [clearBtnColor, setClearBtnColor] = useState(`${COLORS.grayLight}`);
    const [optionsSort] = useState([
        { value: 1, label: 'Prvo najjefinije' },
        { value: 2, label: 'Prvo najskuplje' },
        { value: 3, label: 'A-Z' },
        { value: 4, label: 'Z-A' },
    ]);

    // sortiranje artikala
    const onSelectChange = async (e) => {
        setSelectedSort({ value: e.value, label: e.label });
        var copyArray = [...filteredArticles];
        setFilteredArticles(sort(copyArray, e.value));
    };

    // on search -> filtritranje artikala po nazivu
    useEffect(() => {
        setFilteredArticles(
            showArticleList.filter((article) => {
                return article.Name.toLowerCase().includes(
                    searchValue.toLowerCase()
                );
            })
        );
        setCurrentPage(1);
    }, [searchValue]);

    return (
        <Wrapper>
            <div className='searchBar'>
                <input
                    placeholder='Pretražite artikle po nazivu...'
                    name='searchArticle'
                    type='text'
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                    autoComplete='off'
                />
                <div className='search'>
                    <SearchIcon width='25px' fill={`${COLORS.grayLight}`} />
                </div>
                {searchValue && (
                    <ClearBtn
                        type='button'
                        onClick={() => setSearchValue('')}
                        onMouseEnter={() =>
                            setClearBtnColor(`${COLORS.grayTextDark}`)
                        }
                        onMouseLeave={() =>
                            setClearBtnColor(`${COLORS.grayLight}`)
                        }
                        className='clear'
                    >
                        <XIcon width='15px' fill={clearBtnColor} />
                    </ClearBtn>
                )}
            </div>

            <div className='sort'>
                <CustomSelect
                    isSearchable={false}
                    options={optionsSort}
                    onChange={onSelectChange}
                    defaultValue={{
                        value: selectedSort.value,
                        label: selectedSort.label,
                    }}
                />
            </div>
        </Wrapper>
    );
};

export default SearchAndSortArticles;

import { API_URL } from '../../constants/index';

export async function sendMail(userId) {
    try {
        const res = await fetch(`${API_URL}/send/mail`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                UserId: userId,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function sendRecoverCredentialsMail(username) {
    try {
        const res = await fetch(`${API_URL}/send/mail/recovery/${username}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

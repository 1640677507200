import React, { createContext, useState } from 'react';

export const DataContext = createContext(null);

export const DataProvider = (props) => {
    const [articleQuantity, setArticleQuantity] = useState(1);
    const [articlePrice, setArticlePrice] = useState('');
    const [offerPrice, setOfferPrice] = useState('');
    const [articleName, setArticleName] = useState('');
    const [articleMeasuringUnit, setArticleMeasuringUnit] = useState('');
    const [articleDescription, setArticleDescription] = useState('');
    const [selectedArticleId, setSelectedArticleId] = useState('');

    return (
        <DataContext.Provider
            value={{
                selectedArticleId,
                setSelectedArticleId,
                articleQuantity,
                setArticleQuantity,
                articlePrice,
                setArticlePrice,
                offerPrice,
                setOfferPrice,
                articleName,
                setArticleName,
                articleMeasuringUnit,
                setArticleMeasuringUnit,
                articleDescription,
                setArticleDescription,
            }}
        >
            {props.children}
        </DataContext.Provider>
    );
};

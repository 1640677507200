import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import {
    getBillingMethods,
    getDeliveryMethods,
    getShippingMethods,
} from '../../../services/masterData';
import { getClientList } from '../../../services/user/client';
import CustomSelect from '../../Elements/CustomSelect';
import {
    Bar,
    Label,
    Input,
    LabelEdit,
    EditIcon,
} from '../../Elements/CustomInput';
import { DataContext } from '../../../context/MasterDataContext';
import { COLORS } from '../../../assets/css/_colors';
import editIcon from '../../../assets/images/edit.svg';

const Row = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 50%);
    margin: 15px 0;

    .element--custom {
        display: grid;
        grid-template-columns: 130px auto;
    }

    @media (max-width: 767px) {
        grid-template-columns: 100%;
    }
`;

const Element = styled.data`
    width: ${(props) => props.width};
    position: relative;
    padding: 15px 0;
    border-bottom: 1px solid ${COLORS.grayLight};
    color: ${COLORS.green};

    @media (max-width: 767px) {
        width: 100%;
    }
`;

const RowCustom = styled(Row)`
    grid-template-columns: 150px 50%;
    align-items: center;
    padding: 0;
    color: gray;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 20px;

    @media (max-width: 767px) {
        grid-template-columns: 150px auto;
    }
`;

const MasterInfoEditData = ({}) => {
    const userId = JSON.parse(localStorage.getItem('userId'));
    const token = JSON.parse(localStorage.getItem('token'));
    let { year, number, articlesVisited } = useParams();
    const { register, setValue, errors, watch } = useForm();
    const [optionsBilling, setOptionsBilling] = useState([]);
    const [optionsShipping, setOptionsShipping] = useState([]);
    const [optionsClient, setOptionsClient] = useState([]);
    const [currentClientIndex, setCurrentClientIndex] = useState('');

    const {
        address,
        setAddress,
        deliveryAddress,
        setDeliveryAddress,
        billingMethodName,
        setBillingMethodName,
        currentBillingValue,
        setCurrentBillingValue,
        currentShippingValue,
        setCurrentShippingValue,
        shippingMethodName,
        setShippingMethodName,
        setCurrentClientValue,
        currentClientValue,
        currentClientName,
        setCurrentClientName,
    } = useContext(DataContext);

    useEffect(() => {
        fetchBillingMethods();
        fetchShippingMethods();
        fetchClientList();
    }, []);

    const fetchClientList = async () => {
        try {
            const data = await getClientList(token, userId);
            data.forEach((element, index) => {
                let elementAddress = `${element.Address}, ${element.ZipCode}, ${element.City}`;
                let elementDeliveryAddress = `${element.DeliveryAddress}, ${element.DeliveryZipCode}, ${element.DeliveryCity}`;
                let label = `${element.KomitName} - ${element.Address}, ${element.ZipCode}, ${element.City}`;

                // ako je novi master i nije se islo u artikle -> postavi clienta na prvoga iz liste
                if (
                    year === undefined &&
                    number === undefined &&
                    index === 0 &&
                    articlesVisited !== 'true'
                ) {
                    setCurrentClientValue(element.Id);
                    setCurrentClientName(label);
                    setCurrentClientIndex(0);
                    setValue('addressInput', elementAddress);
                    setValue('deliveryAddressInput', elementDeliveryAddress);
                    setAddress(elementAddress);
                    setDeliveryAddress(elementDeliveryAddress);
                }
                // ako je novi master i posjeceni su artikli -> postavi clienta na onog vec odabranog
                else if (
                    year === undefined &&
                    number === undefined &&
                    element.Id === currentClientValue &&
                    articlesVisited === 'true'
                ) {
                    setCurrentClientIndex(index);
                    setValue('addressInput', address);
                    setValue('deliveryAddressInput', deliveryAddress);
                }
                // ako je stari master -> postavi clienta na onog vec odabranog dobijenog preko API requesta
                else if (
                    year !== undefined &&
                    number !== undefined &&
                    element.Id === currentClientValue
                ) {
                    setCurrentClientName(label);
                    setCurrentClientIndex(index);
                    setValue('addressInput', address);
                    setValue('deliveryAddressInput', deliveryAddress);
                }

                var item = {
                    value: index.toString(),
                    label: label,
                    id: element.Id,
                    firmName: element.KomitName,
                    firmId: element.FirmId,
                    address: elementAddress,
                    deliveryAddress: elementDeliveryAddress,
                };
                setOptionsClient((prevState) => [...prevState, item]);
            });
        } catch (e) {
            console.log(e);
        }
    };

    const fetchShippingMethods = async () => {
        try {
            const data = await getShippingMethods(token, userId);
            data.forEach((element) => {
                var item = {
                    value: element.Id,
                    label: element.Name.toUpperCase(),
                };
                setOptionsShipping((prevState) => [...prevState, item]);
            });
        } catch (e) {
            console.log(e);
        }
    };

    const fetchBillingMethods = async () => {
        try {
            const data = await getBillingMethods(token, userId);
            data.forEach((element) => {
                var item = {
                    value: element.Id,
                    label: element.Name.toUpperCase(),
                };
                setOptionsBilling((prevState) => [...prevState, item]);
            });
        } catch (e) {
            console.log(e);
        }
    };

    function onSelectBillingChange(e) {
        if (e === null) {
            setCurrentBillingValue(4);
            setBillingMethodName(optionsBilling[4].label);
        } else {
            setCurrentBillingValue(e.value);
            optionsBilling.map((item) => {
                if (item.value === e.value) {
                    setBillingMethodName(item.label);
                }
            });
        }
    }

    function onSelectShippingChange(e) {
        if (e === null) {
            setCurrentShippingValue(0);
            setShippingMethodName(optionsShipping[0].label);
        } else {
            setCurrentShippingValue(e.value);
            optionsShipping.map((item) => {
                if (item.value === e.value) {
                    setShippingMethodName(item.label);
                }
            });
        }
    }

    function onSelectClientChange(e) {
        if (e === null) {
            setCurrentClientValue(optionsClient[0].id);
            setCurrentClientIndex(optionsClient[0].value);
            setCurrentClientName(optionsClient[0].label);
            setValue('addressInput', optionsClient[0].address);
            setValue('deliveryAddressInput', optionsClient[0].deliveryAddress);
            setAddress(optionsClient[0].address);
            setDeliveryAddress(optionsClient[0].deliveryAddress);
        } else {
            setCurrentClientValue(e.id);
            setCurrentClientIndex(e.value);
            setCurrentClientName(e.label);
            setValue('addressInput', e.address);
            setValue('deliveryAddressInput', e.deliveryAddress);
            setAddress(e.address);
            setDeliveryAddress(e.deliveryAddress);
        }
    }

    return (
        <div>
            <RowCustom>
                <label
                    style={{
                        color: `${COLORS.green}`,
                    }}
                >
                    Odaberite lokacije:
                </label>
                <div>
                    {!!(
                        optionsClient.length > 0 && currentClientIndex !== ''
                    ) && (
                        <CustomSelect
                            isSearchable={false}
                            name='selectLocation'
                            placeholder='Odaberite lokaciju...'
                            options={optionsClient}
                            onChange={onSelectClientChange}
                            defaultValue={{
                                value: currentClientIndex,
                                label: currentClientName,
                            }}
                        />
                    )}
                </div>
            </RowCustom>

            <Row>
                <Element width='95%' style={{ border: 'none' }}>
                    <label>Adresa: </label>
                    <EditIcon style={{ marginTop: '0' }} src={editIcon} />
                    <Input
                        name='addressInput'
                        placeholder='Ulica, poštanski broj, grad'
                        type='text'
                        ref={register}
                        autoComplete='off'
                        onChange={(e) => setAddress(watch('addressInput'))}
                    />
                    <Bar />
                </Element>
                <Element
                    width='95%'
                    style={{
                        justifySelf: 'end',
                        border: 'none',
                    }}
                >
                    <label>Dostavna adresa: </label>
                    <EditIcon style={{ marginTop: '0' }} src={editIcon} />
                    <Input
                        placeholder='Ulica, poštanski broj, grad'
                        ref={register}
                        name='deliveryAddressInput'
                        type='text'
                        autoComplete='off'
                        onChange={(e) =>
                            setDeliveryAddress(watch('deliveryAddressInput'))
                        }
                    />
                    <Bar />
                </Element>
            </Row>

            <Row>
                <Element
                    className='element--custom'
                    width='95%'
                    style={{
                        marginBottom: '10px',
                        border: 'none',
                        alignItems: 'center',
                        padding: '0',
                        color: 'gray',
                    }}
                >
                    <label
                        style={{
                            color: `${COLORS.green}`,
                        }}
                    >
                        Način plaćanja:
                    </label>
                    <div>
                        {currentBillingValue && (
                            <CustomSelect
                                isSearchable={false}
                                isClearable={false}
                                name='selectBillingMethod'
                                placeholder='Odaberite način plačanja...'
                                options={optionsBilling}
                                onChange={onSelectBillingChange}
                                defaultValue={{
                                    value: currentBillingValue,
                                    label: billingMethodName,
                                }}
                            />
                        )}
                        {!currentBillingValue && optionsBilling[0] && (
                            <CustomSelect
                                isSearchable={false}
                                isClearable={false}
                                name='selectBillingMethod'
                                placeholder='Odaberite način plačanja...'
                                options={optionsBilling}
                                onChange={onSelectBillingChange}
                                defaultValue={{
                                    value: optionsBilling[0].value,
                                    label: optionsBilling[0].label,
                                }}
                            />
                        )}
                    </div>
                </Element>

                <Element
                    className='element--custom'
                    width='95%'
                    style={{
                        justifySelf: 'end',
                        marginBottom: '10px',
                        border: 'none',
                        alignItems: 'center',
                        padding: '0',
                        color: 'gray',
                    }}
                >
                    <label
                        style={{
                            color: `${COLORS.green}`,
                        }}
                    >
                        Način otpreme:
                    </label>
                    <div>
                        {currentShippingValue && (
                            <CustomSelect
                                isSearchable={false}
                                name='selectShippingMethod'
                                options={optionsShipping}
                                onChange={onSelectShippingChange}
                                defaultValue={{
                                    value: currentShippingValue,
                                    label: shippingMethodName,
                                }}
                            />
                        )}
                        {!currentShippingValue && optionsShipping[0] && (
                            <CustomSelect
                                isSearchable={false}
                                name='selectShippingMethod'
                                options={optionsShipping}
                                onChange={onSelectShippingChange}
                                defaultValue={{
                                    value: optionsShipping[0].value,
                                    label: optionsShipping[0].label,
                                }}
                            />
                        )}
                    </div>
                </Element>
            </Row>
        </div>
    );
};

export default MasterInfoEditData;

// const fetchClientList = async () => {
//     try {
//         const data = await getClientList(token, userId);
//         data.forEach((element, index) => {
//             let elementAddress = `${element.Address}, ${element.ZipCode}, ${element.City}`;
//             let elementDeliveryAddress = `${element.DeliveryAddress}, ${element.DeliveryZipCode}, ${element.DeliveryCity}`;
//             let label = `${element.KomitName} - ${element.Address}, ${element.ZipCode}, ${element.City}`;

//             if (year === undefined && number === undefined) {
//                 if (index === 0 && articlesVisited !== 'true') {
//                     setCurrentClientValue(element.Id);
//                     setCurrentClientName(label);
//                     setCurrentClientIndex(0);
//                     setValue('addressInput', elementAddress);
//                     setValue(
//                         'deliveryAddressInput',
//                         elementDeliveryAddress
//                     );
//                     setAddress(elementAddress);
//                     setDeliveryAddress(elementDeliveryAddress);
//                 } else {
//                     console.log('poznata adresa');
//                     setValue('addressInput', address);
//                     setValue('deliveryAddressInput', deliveryAddress);
//                 }
//             } else if (
//                 year !== undefined &&
//                 number !== undefined &&
//                 element.Id === currentClientValue
//             ) {
//                 console.log('ovo je stvar');
//                 setCurrentClientName(label);
//                 setCurrentClientIndex(index);
//                 setValue('addressInput', address);
//                 setValue('deliveryAddressInput', deliveryAddress);
//             }

//             var item = {
//                 value: index.toString(),
//                 label: label,
//                 id: element.Id,
//                 firmName: element.KomitName,
//                 firmId: element.FirmId,
//                 address: elementAddress,
//                 deliveryAddress: elementDeliveryAddress,
//             };
//             setOptionsClient((prevState) => [...prevState, item]);
//         });
//     } catch (e) {
//         console.log(e);
//     }
// };

import React, { useState, useEffect, useContext } from 'react';
import DetailInfoSaved from '../../OfferRequest/Detail/DetailInfoSaved';
import MasterInfoSaved from '../../OfferRequest/Master/MasterInfoSaved';
import Footer from '../../Footer/Footer';
import { useParams } from 'react-router-dom';
import { getDetailInfo } from '../../../services/offerRequest/detail';
import { Title } from '../../../assets/css/_elements';
import { Wrapper, Container } from '../../../assets/css/_pages';
import { getUserInfo } from '../../../services/user/info';
import { getMasterInfo } from '../../../services/offerRequest/master';
import { Spinner } from '../../Elements/Spinner';
import { DefaultInfo } from '../../Elements/DefaultInfo';
import { DataContext } from '../../../context/MasterDataContext';
import { ReactComponent as ArrowNext } from '../../../assets/images/arrow_nextTitle.svg';

const DetailSavedPage = (props) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = JSON.parse(localStorage.getItem('userId'));
    const { year, number, id } = useParams();
    const [loading, setLoading] = useState(true);
    // Master
    const [date, setDate] = useState('');
    const [userType, setUserType] = useState('');
    const [masterStatus, setMasterStatus] = useState('');
    const [repairmen, setRepairmen] = useState();
    const [workOrder, setWorkOrder] = useState();
    // User
    const [user, setUser] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [firmName, setFirmName] = useState('');
    // Detail
    const [description, setDescription] = useState('');
    const [response, setResponse] = useState('');
    const [position, setPosition] = useState(0);
    const [statusName, setStatusName] = useState();
    const [articleName, setArticleName] = useState('');
    const [articlePrice, setArticlePrice] = useState(0);
    const [articleQuantity, setArticleQuantity] = useState(0);
    const [articleId, setArticleId] = useState(0);
    const [price, setPrice] = useState(0);
    const [measuringUnit, setMeasuringUnit] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    const {
        setAddress,
        setDeliveryAddress,
        setDeliveryMethodName,
        setBillingMethodName,
        setCurrentBillingValue,
        setCurrentDeliveryValue,
    } = useContext(DataContext);

    const fetchData = async () => {
        try {
            const detail = await getDetailInfo(token, id);
            setPosition(detail.Position);
            setDescription(detail.Description);
            setResponse(detail.Response);
            setStatusName(detail.StatusName);
            setArticleId(detail.ArticleId);
            setArticleName(detail.ArticleName);
            setArticlePrice(detail.ArticlePrice);
            setArticleQuantity(detail.RequiredQuantity);
            setPrice(detail.Price);
            setMeasuringUnit(detail.MeasuringUnit);

            const data = await getMasterInfo(token, year, number);
            setMasterStatus(data.Status);
            setDate(data.Date);
            setAddress(data.Address);
            setDeliveryAddress(data.DeliveryAddress);
            setCurrentDeliveryValue(data.DeliveryMethod);
            setDeliveryMethodName(data.DeliveryMethodName);
            setCurrentBillingValue(data.BillingMethod);
            setBillingMethodName(data.BillingMethodName);
            setUserType(data.UserType);
            setWorkOrder(data.WorkOrder);

            const userData = await getUserInfo(token, userId);
            setFirmName(userData.FirmName);
            setPhone(userData.Phone);
            setEmail(userData.Email);
            setUser(userData.FirstName + ' ' + userData.LastName);
            setUserType(data.UserType);

            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    };

    return (
        <Wrapper>
            <DefaultInfo />
            <Container>
                {loading ? (
                    <Spinner style={{ marginTop: '100px' }} />
                ) : (
                    <div>
                        <div
                            style={{ display: 'flex', alignItems: 'flex-end' }}
                        >
                            <ArrowNext style={{ marginRight: '20px' }} />
                            <Title>Upit za ponudom</Title>
                        </div>
                        <MasterInfoSaved
                            masterYear={year}
                            masterNumber={number}
                            date={date}
                            status={masterStatus}
                            user={user}
                            firmName={firmName}
                            userType={userType}
                            phone={phone}
                            email={email}
                        />

                        <div
                            style={{ display: 'flex', alignItems: 'flex-end' }}
                        >
                            <ArrowNext style={{ marginRight: '20px' }} />
                            <Title>Stavka upita za ponudom</Title>
                        </div>

                        <DetailInfoSaved
                            workOrder={workOrder}
                            position={position}
                            statusName={statusName}
                            articleId={articleId}
                            articleName={articleName}
                            articlePrice={articlePrice}
                            articleQuantity={articleQuantity}
                            measuringUnit={measuringUnit}
                            price={price}
                            description={description}
                            response={response}
                        />
                    </div>
                )}
            </Container>
            <Footer />
        </Wrapper>
    );
};

export default DetailSavedPage;

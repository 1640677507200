import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowPrevious } from '../../../assets/images/arrow_previous.svg';
import { ReactComponent as ArrowNext } from '../../../assets/images/arrow_next.svg';
import { ReactComponent as ArrowPreviousDouble } from '../../../assets/images/arrow_double_left.svg';
import { ReactComponent as ArrowNextDouble } from '../../../assets/images/arrow_double_right.svg';
import { COLORS } from '../../../assets/css/_colors';
import CustomSelect from '../../Elements/CustomSelect';
import { PageNavButton, PageButton } from '../../Elements/CustomButton';

const Pages = styled.div`
    margin: 20px 0;
    display: flex;
    align-items: center;

    div {
        margin: 0 10px;

        button:first-child {
            border-radius: 5px 0 0 5px;
        }
        button:last-child {
            border-radius: 0 5px 5px 0;
        }

        .single {
            border-radius: 5px !important;
        }

        .selected {
            background-color: ${COLORS.greenLight};
            color: white;
        }
    }
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: ${(props) =>
        props.middle === true ? '100%' : 'repeat(3, auto)'};
    margin-top: 20px;
    justify-items: center;

    @media (max-width: 767px) {
        grid-template-columns: 100%;
        justify-items: center;
    }

    @media (max-width: 480px) {
    }
`;

const ArticlesPerPage = styled.div`
    display: flex;
    align-items: center;
    margin-left: 20px;

    @media (max-width: 767px) {
        margin-left: 0;
    }

    @media (max-width: 480px) {
    }
`;

const ArticlesNumber = styled.div`
    color: ${COLORS.grayTextLight};
    align-self: center;
    margin: 10px 0;
`;

const Pagination = ({
    bottom,
    loading,
    articlesPerPage,
    totalArticles,
    currentPage,
    perPage,
    setPerPage,
    setFirstPage,
    setCurrentPage,
    // paginate,
    // setPreviousPage,
    // nextPage,
    // setLastPage,
}) => {
    const [firstBtnColor, setFirstBtnColor] = useState('');
    const [lastBtnColor, setLastBtnColor] = useState('');
    const [previousBtnColor, setPreviousBtnColor] = useState('');
    const [nextBtnColor, setNextBtnColor] = useState('');
    const [width, setWidth] = useState(window.innerWidth);
    const pageNumbersShow = [];
    const pageNumbers = [];
    const [options] = useState([
        { value: 1, label: perPage },
        { value: 2, label: 50 },
        { value: 3, label: 100 },
    ]);

    const updateDimensions = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    for (let i = 1; i <= Math.ceil(totalArticles / articlesPerPage); i++) {
        pageNumbers.push(i);
    }

    if (width > 500) {
        if (pageNumbers.length <= 5) {
            for (let index = 0; index < pageNumbers.length; index++) {
                pageNumbersShow.push(pageNumbers[index]);
            }
        } else {
            if (currentPage === 1 || currentPage === 2) {
                for (let index = 0; index < 5; index++) {
                    pageNumbersShow.push(pageNumbers[index]);
                }
            } else if (
                currentPage === pageNumbers.length ||
                currentPage === pageNumbers.length - 1
            ) {
                for (
                    let index = pageNumbers.length - 5;
                    index < pageNumbers.length;
                    index++
                ) {
                    pageNumbersShow.push(pageNumbers[index]);
                }
            } else {
                for (
                    let index = currentPage - 3;
                    index <= currentPage + 1;
                    index++
                ) {
                    pageNumbersShow.push(pageNumbers[index]);
                }
            }
        }
    } else {
        if (pageNumbers.length <= 3) {
            for (let index = 0; index < pageNumbers.length; index++) {
                pageNumbersShow.push(pageNumbers[index]);
            }
        } else {
            if (currentPage === 1) {
                for (let index = 0; index < 3; index++) {
                    pageNumbersShow.push(pageNumbers[index]);
                }
            } else if (currentPage === pageNumbers.length) {
                for (
                    let index = pageNumbers.length - 2;
                    index < pageNumbers.length;
                    index++
                ) {
                    pageNumbersShow.push(pageNumbers[index]);
                }
            } else {
                console.log(pageNumbers[currentPage], currentPage);
                for (
                    let index = currentPage - 2;
                    index <= currentPage;
                    index++
                ) {
                    pageNumbersShow.push(pageNumbers[index]);
                }
            }
        }
    }

    const onPerPageChange = (e) => {
        if (e !== null) {
            setPerPage(e.label);
            setCurrentPage(1);
        }
    };

    // paginate -> metode za prikaz artikala po stranicama
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const setPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };
    const nextPage = (pageNumbers) => {
        if (currentPage < pageNumbers.length) {
            setCurrentPage(currentPage + 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };
    const setLastPage = (pageNumbers) => {
        setCurrentPage(pageNumbers.length);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div>
            {!loading && (
                <Container middle={bottom}>
                    {!bottom && (
                        <ArticlesNumber>
                            <span
                                style={{
                                    color: `${COLORS.grayTextDark}`,
                                    fontWeight: 'bold',
                                    marginRight: '5px',
                                }}
                            >
                                {totalArticles}
                            </span>
                            proizvoda
                        </ArticlesNumber>
                    )}

                    <Pages>
                        <PageNavButton
                            style={{ marginRight: '5px' }}
                            type='button'
                            onClick={() => setFirstPage()}
                            onMouseEnter={() =>
                                setFirstBtnColor(`${COLORS.green}`)
                            }
                            onMouseLeave={() =>
                                setFirstBtnColor(`${COLORS.grayTextDark}`)
                            }
                        >
                            <ArrowPreviousDouble
                                width='15px'
                                height='15px'
                                fill={firstBtnColor}
                            />
                        </PageNavButton>
                        <PageNavButton
                            type='button'
                            onClick={() => setPreviousPage()}
                            onMouseEnter={() =>
                                setPreviousBtnColor(`${COLORS.green}`)
                            }
                            onMouseLeave={() =>
                                setPreviousBtnColor(`${COLORS.grayTextDark}`)
                            }
                        >
                            <ArrowPrevious
                                width='15px'
                                fill={previousBtnColor}
                            />
                        </PageNavButton>

                        <div>
                            {pageNumbersShow.length === 1 ? (
                                <PageButton
                                    className='selected single'
                                    key={pageNumbers[0]}
                                >
                                    {pageNumbers[0]}
                                </PageButton>
                            ) : (
                                pageNumbersShow.map((number) => {
                                    return currentPage === number ? (
                                        <PageButton
                                            className='selected'
                                            onClick={() => paginate(number)}
                                            key={number}
                                        >
                                            {number}
                                        </PageButton>
                                    ) : (
                                        <PageButton
                                            onClick={() => paginate(number)}
                                            key={number}
                                        >
                                            {number}
                                        </PageButton>
                                    );
                                })
                            )}
                        </div>

                        <PageNavButton
                            type='button'
                            onClick={() => nextPage(pageNumbers)}
                            onMouseEnter={() =>
                                setNextBtnColor(`${COLORS.green}`)
                            }
                            onMouseLeave={() =>
                                setNextBtnColor(`${COLORS.grayTextDark}`)
                            }
                        >
                            <ArrowNext width='15px' fill={nextBtnColor} />
                        </PageNavButton>
                        <PageNavButton
                            style={{ marginLeft: '5px' }}
                            type='button'
                            onClick={() => setLastPage(pageNumbers)}
                            onMouseEnter={() =>
                                setLastBtnColor(`${COLORS.green}`)
                            }
                            onMouseLeave={() =>
                                setLastBtnColor(`${COLORS.grayTextDark}`)
                            }
                        >
                            <ArrowNextDouble
                                width='15px'
                                height='15px'
                                fill={lastBtnColor}
                            />
                        </PageNavButton>
                    </Pages>

                    {!bottom && (
                        <ArticlesPerPage>
                            <span style={{ color: `${COLORS.grayTextLight}` }}>
                                Artikala po stranici
                            </span>
                            <div style={{ width: '80px', marginLeft: '20px' }}>
                                <CustomSelect
                                    isSearchable={false}
                                    options={options}
                                    onChange={onPerPageChange}
                                    placeholder={'Odaberite grupu...'}
                                    defaultValue={{
                                        value: options[0].value,
                                        label: options[0].label,
                                    }}
                                />
                            </div>
                        </ArticlesPerPage>
                    )}
                </Container>
            )}
        </div>
    );
};

export default Pagination;

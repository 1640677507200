import styled from 'styled-components';
import { COLORS } from '../css/_colors';

export const UserInfoForm = styled.form`
    width: 100%;
    margin-top: 50px;
    transition: all 1s ease;

    @media (max-width: 767px) {
        padding: 0;
    }
`;

export const Form = styled.form`
    max-width: 500px;
    margin: 0 auto;
    margin-top: 50px;
    transition: all 1s ease;

    @media (max-width: 767px) {
        max-width: 420px;
    }

    @media (max-width: 480px) {
        max-width: 300px;
    }
`;

export const Input = styled.input`
    width: 100%;
    border: 0;
    border-bottom: 1px solid ${COLORS.grayLight};
    display: block;
    height: 40px;
    outline: none;
    font-size: 17px;
    background-color: transparent !important;
    color: ${COLORS.grayDark};

    &:focus ~ .bar::before,
    &:focus ~ .bar::after {
        width: 50%;
    }
`;

export const Row = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 50%);

    @media (max-width: 480px) {
        grid-template-columns: 100%;
    }
`;

export const RowAddress = styled(Row)`
    grid-template-columns: repeat(2, 50%);

    @media (max-width: 600px) {
        grid-template-columns: 100%;
    }
`;

export const Element = styled.div`
    width: ${(props) => props.width};
    position: relative;
    padding: 15px 0;

    @media (max-width: 480px) {
        width: 100%;
    }
`;

export const LoginForm = styled.form`
    max-width: 300px;
    padding: 1em 2em 2em 2em;
    margin: 30px auto;

    @media (max-width: 480px) {
        max-width: 400px;
    }
`;

export const LocalError = styled.p`
    color: ${COLORS.red} !important;
    font-size: 13px;
    margin-bottom: 0;
`;

export const LoginRow = styled.div`
    display: flex;
`;

export const LabelContainer = styled.div`
    position: absolute;
    top: 30px;
    transition: all 0.2s ease;
    font-size: 16px;
    color: ${COLORS.green};
    z-index: -1;
`;

import { useState, useEffect } from 'react';
import { getArticleListByGroup } from '../../../services/offerRequest/article';

export default function useCustomHook(
    selectedGroup,
    setLoading,
    setFilteredArticles,
    setDefaultPrice
) {
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = JSON.parse(localStorage.getItem('userId'));
    const [data, setData] = useState([]);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            setData([]);
            setFilteredArticles([]);
            try {
                const response = await getArticleListByGroup(
                    token,
                    userId,
                    selectedGroup
                );
                let maxPrice = 0;
                response.forEach((item) => {
                    if (item.Price > maxPrice) maxPrice = item.Price;
                    var article = {
                        Id: item.Id,
                        MeasuringUnit: item.MeasuringUnit,
                        Name: item.Name,
                        Price: item.Price.toFixed(2),
                    };
                    setData((prevState) => [...prevState, article]);
                    setFilteredArticles((prevState) => [...prevState, article]);
                });
                setDefaultPrice([0, maxPrice]);
                setLoading(false);
            } catch (e) {
                setLoading(false);
                console.log(e);
            }
        };

        fetchData();
    }, [selectedGroup]);

    return data;
}

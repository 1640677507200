import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonLoading } from '../Elements/CustomButton';
import { getUserInfo } from '../../services/user/info';
import { updateUsername } from '../../services/user/credentials';
import { sendMail } from '../../services/mail/credentialsMail';
import { LinkForm } from '../Elements/LinkForm';
import { Spinner, SpinnerBtn } from '../Elements/Spinner';
import Error from '../Elements/Error';
import { Bar, Label } from '../Elements/CustomInput';
import { COLORS } from '../../assets/css/_colors';
import loginUserNameIcon from '../../assets/images/login-user.svg';
import loginPasswordIcon from '../../assets/images/login-lock.svg';
import {
    Form,
    Input,
    Row,
    Element,
    LabelContainer,
} from '../../assets/css/_forms';
import { LocalError } from '../../assets/css/_elements';

export const ChangeUsername = ({}) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = JSON.parse(localStorage.getItem('userId'));
    const { register, handleSubmit, setValue, errors, watch } = useForm();
    const [error, setError] = useState('');
    const [usernameCSS, setUsernameCSS] = useState({ top: '0px' });
    const [passwordCSS, setPasswordCSS] = useState({ top: '30px' });
    const [username, setUsername] = useState('');
    const [saveBtnStyle, setSaveBtnStyle] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingForm, setLoadingForm] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        try {
            const userInfo = await getUserInfo(token, userId);
            setLoadingForm(false);
            setUsername(userInfo.Username);
            setValue('username', userInfo.Username);
        } catch (e) {
            console.log(e);
            setLoadingForm(false);
        }
    }

    const onChangeUsernameSubmit = async (data) => {
        if (username === watch('username')) {
            setError(
                'Uneseno korisničko ime je isto kao i vaše trenutno korisničko ime!'
            );
        } else {
            try {
                setLoading(true);
                setSaveBtnStyle({
                    backgroundColor: 'transparent',
                    color: `${COLORS.green}`,
                    border: `2px solid ${COLORS.green}`,
                    paddingTop: '0',
                });
                await updateUsername(token, userId, data);
                await sendMail(userId);
                localStorage.setItem(
                    'actionMessage',
                    'Uspješna promjena korisničkog imena!'
                );
                window.location.reload(false);
            } catch (e) {
                setError(e);
                setLoading(false);
                setValue('password', '');
                setSaveBtnStyle({
                    backgroundColor: `${COLORS.green}`,
                    color: 'white',
                    border: `2px solid ${COLORS.green}`,
                });
            }
        }
    };

    return (
        <div>
            {loadingForm ? (
                <Spinner />
            ) : (
                <Form
                    style={{ width: '250px', autoComplete: 'none' }}
                    onSubmit={handleSubmit(onChangeUsernameSubmit)}
                >
                    <Row style={{ gridTemplateColumns: '100%' }}>
                        <Element
                            width='100%'
                            onBlur={() =>
                                watch('username') === ''
                                    ? setUsernameCSS({
                                          top: '30px',
                                      })
                                    : setUsernameCSS({
                                          top: '0px',
                                      })
                            }
                            onFocus={() => setUsernameCSS({ top: '0px' })}
                        >
                            <Input
                                name='username'
                                type='text'
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Unesite korisničko ime',
                                    },
                                    maxLength: {
                                        value: 25,
                                        message:
                                            'Korisničko ime ne smije imati najviše 25 znakova',
                                    },
                                })}
                                onChange={(e) => setError('')}
                                autoComplete='off'
                            />
                            <Bar />
                            <LabelContainer style={usernameCSS}>
                                <img
                                    style={{
                                        transition: 'all 0.2s ease',
                                        width: '15px',
                                        marginRight: '10px',
                                    }}
                                    src={loginUserNameIcon}
                                    alt='login'
                                />
                                <span>Korisničko ime</span>
                            </LabelContainer>
                            {errors.username && (
                                <LocalError>
                                    {errors.username.message}
                                </LocalError>
                            )}
                        </Element>
                    </Row>

                    <Row style={{ gridTemplateColumns: '100%' }}>
                        <Element
                            width='100%'
                            onBlur={() =>
                                watch('password') === ''
                                    ? setPasswordCSS({
                                          top: '30px',
                                      })
                                    : setPasswordCSS({
                                          top: '0px',
                                      })
                            }
                            onFocus={() => setPasswordCSS({ top: '0px' })}
                        >
                            <Input
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Unesite lozinku',
                                    },
                                    maxLength: {
                                        value: 25,
                                        message:
                                            'Lozinka smije imati najviše 25 znakova',
                                    },
                                    minLength: {
                                        value: 6,
                                        message:
                                            'Lozinka mora imati najmanje 6 znakova',
                                    },
                                })}
                                name='password'
                                type='password'
                                onChange={(e) => setError('')}
                                autoComplete='none'
                            />
                            <Bar />
                            <LabelContainer style={passwordCSS}>
                                <img
                                    style={{
                                        transition: 'all 0.2s ease',
                                        width: '15px',
                                        marginRight: '10px',
                                    }}
                                    src={loginPasswordIcon}
                                    alt='login'
                                />
                                <span>Lozinka</span>
                            </LabelContainer>
                            {errors.password && (
                                <LocalError>
                                    {errors.password.message}
                                </LocalError>
                            )}
                        </Element>
                        <input
                            type='text'
                            autoComplete='on'
                            value=''
                            style={{
                                display: 'none',
                                opacity: 0,
                                position: 'absolute',
                                left: '-100000px',
                            }}
                            readOnly={true}
                        />
                    </Row>

                    {error !== '' && (
                        <Error msg={error} style={{ padding: '0 10px' }} />
                    )}

                    <ButtonLoading style={saveBtnStyle} type='submit'>
                        {loading && <SpinnerBtn />}
                        Spremi
                    </ButtonLoading>

                    <LinkForm
                        to={'/credentials/recover'}
                        text={'Zaboravili ste lozinku?'}
                        linkText={'Klikni ovdje!'}
                    />
                </Form>
            )}
        </div>
    );
};

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { DefaultInfo } from '../../Elements/DefaultInfo';
import Footer from '../../Footer/Footer';
import { ButtonBasic } from '../../Elements/CustomButton';
import { FooterForm } from '../../Elements/FooterForm';
import 'react-notifications-component/dist/theme.css';
import { getMasterListByClientId } from '../../../services/offerRequest/master';
import { getClientList, deleteClient } from '../../../services/user/client';
import { ClientForm } from '../../Forms/ClientForm';
import { Notification } from '../../Elements/Notification';
import { ButtonAddClient } from '../../Elements/CustomButton';
import { DialogWarning } from '../../Elements/Dialog';
import { Spinner } from '../../Elements/Spinner';
import { Wrapper, Container } from '../../../assets/css/_pages';
import { Title } from '../../../assets/css/_elements';
import { COLORS } from '../../../assets/css/_colors';

import { ReactComponent as DeleteIcon } from '../../../assets/images/delete.svg';
import mapIcon from '../../../assets/images/map.svg';
import locationIcon from '../../../assets/images/location.svg';
import checkedIcon from '../../../assets/images/checked.svg';

const DeleteButton = styled(ButtonBasic)`
    border: none;
    opacity: 1;

    :hover {
        background: none;
    }

    span {
        color: ${COLORS.grayTextDark};
        margin-left: 10px;
        align-self: center;
    }
`;

const LocationList = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 50%);
    justify-items: center;

    @media (max-width: 780px) {
        grid-template-columns: 100%;
    }
`;

const Card = styled.div`
    margin: 2px;
    padding: 5px 10px;
    align-items: center;
    background: rgb(160, 204, 160);
    /* opacity: 0.6; */
    color: white;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 85% auto;
    transition: all 0.3s ease;
    cursor: pointer;
    width: 99%;
    max-width: 500px;
    position: relative;

    .checked {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        width: 30px;
        visibility: hidden;
        transition: all 0.1s ease;
    }

    &:hover {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2),
            0 4px 20px 0px rgba(0, 0, 0, 0.19);
    }

    .name {
        font-weight: bold;
        margin-bottom: 0;
        color: ${COLORS.grayLightBackground};
    }

    .address {
        display: inline-flex;
        img {
            margin-right: 5px;
        }
    }

    @media (max-width: 780px) {
    }
`;

const Location = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
`;

const ClientPage = (props) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = JSON.parse(localStorage.getItem('userId'));
    const history = useHistory();
    const actionMessage = localStorage.getItem('actionMessage');
    const [selectedClient, setSelectedClient] = useState();
    const [optionsLocation, setOptionsLocation] = useState([]);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const clientList = await getClientList(token, userId);
            clientList.forEach((element, index) => {
                if (index === 0) setSelectedClient(element.Id);
                var client = {
                    Id: element.Id,
                    FirmName: element.KomitName,
                    Address: `${element.Address}, ${element.ZipCode}, ${element.City}`,
                };
                setOptionsLocation((prevState) => [...prevState, client]);
            });
            setLoading(false);
        } catch (e) {
            setLoading(false);
            // setServerError(e);
        }
    };

    const onClientDeleteClick = async () => {
        try {
            await deleteClient(token, userId, selectedClient);
            localStorage.setItem('actionMessage', 'Uspješno obrisan komitent!');
            window.location.reload(false);
        } catch (e) {
            console.log(e);
        }
    };

    const onDeleteClient = async (clientId) => {
        const response = await getMasterListByClientId(token, userId, clientId);
        response === 200 ? setOpenDelete(true) : setOpen(true);
    };

    return (
        <Wrapper>
            <Notification />
            <DefaultInfo />
            {loading ? (
                <Spinner style={{ marginTop: '100px' }} />
            ) : (
                <Container style={{ maxWidth: '800px' }}>
                    <ButtonAddClient
                        type='button'
                        onClick={() => history.push(`/client/new`)}
                    />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '50px',
                        }}
                    >
                        <img
                            style={{ width: '100px', marginRight: '10px' }}
                            src={mapIcon}
                        />
                        <Title
                            style={{
                                marginLeft: '10px',
                                marginBottom: '50px',
                                textAlign: 'center',
                            }}
                        >
                            Lokacije
                        </Title>
                    </div>

                    {optionsLocation.length === 1 ? (
                        <Location>
                            <Card
                                key={optionsLocation[0].Id}
                                onClick={() =>
                                    setSelectedClient(optionsLocation[0].Id)
                                }
                            >
                                <div>
                                    <p className='name'>
                                        {optionsLocation[0].FirmName}
                                    </p>
                                    <div className='address'>
                                        <img src={locationIcon} />
                                        <p>{optionsLocation[0].Address}</p>
                                    </div>
                                </div>
                                <DeleteButton
                                    type='button'
                                    onClick={() => {
                                        onDeleteClient(optionsLocation[0].Id);
                                    }}
                                >
                                    <DeleteIcon
                                        width='18px'
                                        height='18px'
                                        fill={`${COLORS.redErrorText}`}
                                    />
                                </DeleteButton>
                                <img
                                    style={{ visibility: 'visible' }}
                                    className='checked'
                                    src={checkedIcon}
                                />
                            </Card>
                        </Location>
                    ) : (
                        <LocationList>
                            {optionsLocation.map((client) => {
                                return (
                                    <Card
                                        key={client.Id}
                                        onClick={() =>
                                            setSelectedClient(client.Id)
                                        }
                                    >
                                        <div>
                                            <p className='name'>
                                                {client.FirmName}
                                            </p>
                                            <div className='address'>
                                                <img src={locationIcon} />
                                                <p>{client.Address}</p>
                                            </div>
                                        </div>
                                        <DeleteButton
                                            type='button'
                                            onClick={() => {
                                                onDeleteClient(client.Id);
                                            }}
                                        >
                                            <DeleteIcon
                                                width='18px'
                                                height='18px'
                                                fill={`${COLORS.redErrorText}`}
                                            />
                                        </DeleteButton>
                                        {selectedClient === client.Id ? (
                                            <img
                                                style={{
                                                    visibility: 'visible',
                                                }}
                                                className='checked'
                                                src={checkedIcon}
                                            />
                                        ) : (
                                            <img
                                                className='checked'
                                                src={checkedIcon}
                                            />
                                        )}
                                    </Card>
                                );
                            })}
                        </LocationList>
                    )}

                    {selectedClient && (
                        <ClientForm
                            edit={true}
                            selectedClient={selectedClient}
                        />
                    )}

                    <FooterForm
                        text={
                            'Nakon što se stranica osvježi, Vaši podatci će biti promijenjeni!'
                        }
                    />
                </Container>
            )}
            <Footer />

            <DialogWarning
                open={open}
                onClose={() => setOpen(false)}
                title={'Upozorenje!'}
                message={
                    'Niste u mogućnosti obrisati ovu lokaciju jer imate prijavljene kvarove na njoj!'
                }
            />

            <DialogWarning
                open={openDelete}
                onClose={() => setOpenDelete(false)}
                title={'Upozorenje!'}
                message={'Jeste sigurni da želite obrisati lokaciju?'}
                onClickCancel={() => setOpenDelete(false)}
                onClickOk={onClientDeleteClick}
                cancelText={'odustani'}
                okText={'obriši'}
            />
        </Wrapper>
    );
};

export default ClientPage;

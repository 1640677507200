import React, { useState, useEffect } from 'react';
import { DefaultInfo } from '../../Elements/DefaultInfo';
import Footer from '../../Footer/Footer';
import 'react-notifications-component/dist/theme.css';
import { FooterForm } from '../../Elements/FooterForm';
import { Notification } from '../../Elements/Notification';
import { ClientForm } from '../../Forms/ClientForm';
import { Title } from '../../../assets/css/_elements';
import { Wrapper, Container } from '../../../assets/css/_pages';

const ClientPage = ({}) => {
    return (
        <Wrapper>
            <Notification />
            <DefaultInfo />
            <Container style={{ maxWidth: '700px' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '50px',
                    }}
                >
                    <img
                        style={{ width: '100px', marginRight: '10px' }}
                        src={require('../../../assets/images/map.svg')}
                    />
                    <Title
                        style={{
                            marginLeft: '10px',
                            marginBottom: '50px',
                            textAlign: 'center',
                        }}
                    >
                        Dodaj lokaciju
                    </Title>
                </div>

                <ClientForm edit={false} />

                <FooterForm
                    text={
                        'Nakon što se stranica osvježi, Vaši podatci će biti promijenjeni!'
                    }
                />
            </Container>
            <Footer />
        </Wrapper>
    );
};

export default ClientPage;

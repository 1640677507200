import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import Login from './Components/Pages/Public/Login';
import Register from './Components/Pages/Public/Register';
import RegisterInfodata from './Components/Pages/Public/RegisterInfodata';
import { AuthProvider, useAuth } from './context/AuthContext';
import Profile from './Components/Pages/Public/Profile';
import DetailSavedPage from './Components/Pages/OfferRequest/DetailSavedPage';
import DetailNewPage from './Components/Pages/OfferRequest/DetailNewPage';
import DetailEditPage from './Components/Pages/OfferRequest/DetailEditPage';
import ArticlesPage from './Components/Pages/OfferRequest/ArticlesPage';
import HomePage from './Components/Pages/OfferRequest/HomePage';
import RecoverCredentials from './Components/Pages/Public/RecoverCredentials';
import ScrollToTop from './Components/Elements/ScrollToTop';
import { ContactPage } from './Components/Pages/Public/ContactPage';
import { DataProvider as DataProviderMaster } from './context/MasterDataContext';
import { DataProvider } from './context/ArticleContext';
import ClientPage from './Components/Pages/OfferRequest/ClientPage';
import ClientNewPage from './Components/Pages/OfferRequest/ClientNewPage';

function PrivateRoute({ children, ...rest }) {
    const authStore = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                authStore.token ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

ReactDOM.render(
    <AuthProvider>
        <Router basename='/Infodata/build/'>
            <ScrollToTop />
            <Switch>
                {/* Bez autorizacije ------------------------------------------------------------------*/}
                <Route path='/login' component={Login} />
                <Route exact path='/register' component={Register} />
                <Route
                    exact
                    path='/register/infodata'
                    component={RegisterInfodata}
                />
                <Route
                    exact
                    path='/credentials/recover'
                    component={RecoverCredentials}
                />

                {/* Autorizacija ------------------------------------------------------------------*/}
                <PrivateRoute exact path='/profile'>
                    <Profile />
                </PrivateRoute>

                <PrivateRoute exact path='/client/list'>
                    <ClientPage />
                </PrivateRoute>

                <PrivateRoute exact path='/client/new'>
                    <ClientNewPage />
                </PrivateRoute>

                <PrivateRoute exact path='/contact-us'>
                    <ContactPage />
                </PrivateRoute>

                {/* PRIJAVA KVARA ------------------------------------------------------------------*/}
                <DataProviderMaster>
                    <DataProvider>
                        {/* Articles Rute ------------------------------------------------------------------ */}
                        <PrivateRoute
                            exact
                            path='/articles/edit/:year/:number/:id'
                        >
                            <ArticlesPage />
                        </PrivateRoute>

                        <PrivateRoute exact path='/articles/new/:year/:number'>
                            <ArticlesPage />
                        </PrivateRoute>
                        {/* UPIT ZA PONUDOM ------------------------------------------------------------------*/}
                        <PrivateRoute exact path='/'>
                            <HomePage />
                        </PrivateRoute>
                        <PrivateRoute path='/detail/:year/:number/:id'>
                            <DetailSavedPage />
                        </PrivateRoute>
                        {/* Novi detalj  ------------------------------------------------------------------*/}
                        <PrivateRoute exact path='/new/detail'>
                            <DetailNewPage />
                        </PrivateRoute>

                        <PrivateRoute
                            exact
                            path='/new/detail/:articleId/:articlesVisited'
                        >
                            <DetailNewPage />
                        </PrivateRoute>
                        <PrivateRoute
                            exact
                            path='/new/detail/:year/:number/:articleId/:articlesVisited'
                        >
                            <DetailNewPage />
                        </PrivateRoute>
                        {/* Edit detalja ------------------------------------------------------------------*/}
                        {/* Treba viditi jeli mi valja ova adresa */}
                        <PrivateRoute
                            exact
                            path='/edit/detail/:year/:number/:id/:articleId/:articlesVisited'
                        >
                            <DetailEditPage />
                        </PrivateRoute>
                    </DataProvider>
                </DataProviderMaster>
            </Switch>
        </Router>
    </AuthProvider>,
    document.getElementById('root')
);

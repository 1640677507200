import React, { useEffect } from 'react';
import styled from 'styled-components';
import ArticleCard from './ArticleCard';
import { getWholeAndDecimal } from '../../../utils/getWholeAndDecimal';

const Container = styled.div`
    margin: 50px auto;
    grid-gap: 5px;
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 767px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 480px) {
        grid-template-columns: 100%;
    }
`;

const ArticleList = ({ showArticleList }) => {
    return (
        <Container>
            {showArticleList.map((item, index) => {
                var price = getWholeAndDecimal(item.Price);
                return (
                    <ArticleCard
                        articlesPage={true}
                        key={index}
                        currentArticleId={item.Id}
                        name={item.Name}
                        wholePrice={price[0]}
                        decimalPrice={price[1]}
                    />
                );
            })}
        </Container>
    );
};

export default ArticleList;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { ButtonLoading } from '../Elements/CustomButton';
import { getUserInfo } from '../../services/user/info';
import { SpinnerBtn, Spinner } from '../Elements/Spinner';
import { contactUs } from '../../services/mail/contactUs';
import { COLORS } from '../../assets/css/_colors';
import { UserInfoForm, Row, Element } from '../../assets/css/_forms';
import { LocalError } from '../../assets/css/_elements';

const Textarea = styled.textarea`
    width: 100%;
    border: 0;
    border: 1px solid ${COLORS.grayLight};
    color: ${COLORS.grayDark};
    display: block;
    outline: none;
    font-size: 17px;
    background-color: white !important;
    resize: none;
    padding-left: 10px;
    padding-top: 10px;
    margin-top: 10px;
    border-radius: 3px;

    &:focus ~ .bar::before,
    &:focus ~ .bar::after {
        width: 50%;
    }
`;

const Error = styled.p`
    color: ${COLORS.red};
    font-size: 13px;
    margin-top: ${(props) => (props.description ? '0' : '13px')};

    @media (max-width: 767px) {
        padding: ${(props) => (props.description ? '0 20px' : '0')};
    }
`;

const Input = styled.input`
    border-radius: 2px;
    border: 1px solid ${COLORS.grayLight};
    font-size: 15px;
    padding: 10px;
    width: 100%;
    color: ${COLORS.grayTextDark};
    margin-top: 5px;
`;

const Star = styled.span`
    position: absolute;
    color: ${COLORS.red} !important;
    margin-left: 5px;
    margin-top: 10px;
    font-size: 12px;
`;

const RowElement = styled(Element)`
    position: relative;
    width: 95%;

    span {
        color: ${COLORS.grayTextDark};
    }

    @media (max-width: 480px) {
        width: 100%;
    }
`;

export const ContactForm = () => {
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = JSON.parse(localStorage.getItem('userId'));
    const [saveBtnStyle, setSaveBtnStyle] = useState({ width: '200px' });
    const [loading, setLoading] = useState(false);
    const [loadingForm, setLoadingForm] = useState(true);
    const [serverError, setServerError] = useState('');
    const [buttonText, setButtonText] = useState('Pošalji');
    const history = useHistory();
    const {
        register,
        handleSubmit,
        setValue,
        setError,
        errors,
        watch,
    } = useForm();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const userInfo = await getUserInfo(token, userId);
            setLoadingForm(false);
            setValue('name', userInfo.FirstName + ' ' + userInfo.LastName);
            setValue('email', userInfo.Email);
        } catch (e) {
            setLoadingForm(false);
            setServerError(e);
        }
    };

    const onInfoSubmit = async (data) => {
        try {
            setLoading(true);
            setSaveBtnStyle({
                backgroundColor: 'transparent',
                color: `${COLORS.green}`,
                border: `2px solid ${COLORS.green}`,
                width: '200px',
            });
            setButtonText('Slanje');
            let dataRequest = {
                Token: token,
                UserId: userId,
                Name: data.name,
                Email: data.email,
                Subject: data.subject,
                Message: data.message,
            };
            await contactUs(dataRequest);
            localStorage.setItem('actionMessage', 'Uspješno poslan e-mail!');
            history.push('/');
        } catch (err) {
            setServerError(err);
            setLoading(false);
            setSaveBtnStyle({
                width: '200px',
                backgroundColor: 'green',
                color: 'white',
                border: '2px solid green',
            });
        }
    };

    return (
        <div>
            {loadingForm ? (
                <Spinner />
            ) : (
                <UserInfoForm onSubmit={handleSubmit(onInfoSubmit)}>
                    <Row>
                        <RowElement>
                            <span>Ime i prezime</span>
                            <Input
                                name='name'
                                type='text'
                                autoComplete='off'
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Unesite ime i prezime',
                                    },
                                })}
                                onChange={(e) => setServerError('')}
                            />
                            <Star>*</Star>
                            {errors.name && (
                                <LocalError>{errors.name.message}</LocalError>
                            )}
                        </RowElement>
                        <RowElement
                            style={{
                                justifySelf: 'end',
                            }}
                        >
                            <span>Email</span>
                            <Input
                                name='email'
                                type='text'
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Unesite email',
                                    },
                                    maxLength: {
                                        value: 50,
                                        message:
                                            'Email može sadržavati najviše 50 znakova',
                                    },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'Unesite ispravan email',
                                    },
                                })}
                                onChange={(e) => setServerError('')}
                                autoComplete='none'
                            />
                            <Star>*</Star>
                            {errors.email && (
                                <LocalError>{errors.email.message}</LocalError>
                            )}
                        </RowElement>
                    </Row>

                    <Row style={{ gridTemplateColumns: '100%' }}>
                        <RowElement style={{ width: '100%' }}>
                            <span>Naslov</span>
                            <Input
                                name='subject'
                                type='text'
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Unesite naslov',
                                    },
                                    maxLength: {
                                        value: 25,
                                        message:
                                            'Naslov smije imati najviše 25 znakova',
                                    },
                                })}
                                onChange={(e) => setServerError('')}
                                autoComplete='none'
                            />
                            <Star>*</Star>
                            {errors.subject && (
                                <LocalError>
                                    {errors.subject.message}
                                </LocalError>
                            )}
                        </RowElement>
                    </Row>

                    <Row style={{ gridTemplateColumns: '100%' }}>
                        <RowElement
                            style={{ width: '100%', padding: '20px 0' }}
                        >
                            <span>Poruka</span>
                            <Textarea
                                rows='5'
                                placeholder='Unesite poruku...'
                                name='message'
                                type='text'
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Unesite poruku',
                                    },
                                    maxLength: {
                                        value: 512,
                                        message: 'Maksimalno 512 znakova',
                                    },
                                })}
                                autoComplete='off'
                            />
                            <Star
                                style={{
                                    top: '45px',
                                    right: '0',
                                    marginLeft: '20px',
                                    right: '-10px',
                                }}
                            >
                                *
                            </Star>
                            {errors.message && (
                                <Error message>{errors.message.message}</Error>
                            )}
                        </RowElement>
                    </Row>

                    {serverError && (
                        <LocalError
                            style={{
                                textAlign: 'center',
                                marginTop: '20px',
                            }}
                        >
                            {serverError}
                        </LocalError>
                    )}
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <ButtonLoading
                            style={saveBtnStyle}
                            type='submit'
                            // onClick={(e) => e.preventDefault()}
                        >
                            {loading && <SpinnerBtn />}
                            {buttonText}
                        </ButtonLoading>
                    </div>
                </UserInfoForm>
            )}
        </div>
    );
};

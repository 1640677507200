import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const StyledButton = styled.button`
    padding: 6px 16px;
    font-size: 14px;
    width: auto;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.4s ease;
    border: none;
    background-color: transparent;
    color: #606060;
`;

export const DialogWarning = ({
    open,
    onClose,
    title,
    message,
    onClickCancel,
    onClickOk,
    cancelText,
    okText,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            // aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText
                    style={{ color: '#111111CC', textSize: '14px' }}
                    id='alert-dialog-description'
                >
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {cancelText && (
                    <StyledButton onClick={onClickCancel}>
                        {cancelText}
                    </StyledButton>
                )}
                <StyledButton style={{ color: '#065FD4' }} onClick={onClickOk}>
                    {okText}
                </StyledButton>
            </DialogActions>
        </Dialog>
    );
};

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonPdf, ButtonBasic, ButtonPDF } from '../../Elements/CustomButton';
import { DialogWarning } from '../../Elements/Dialog';
import styled from 'styled-components';
import DetailCard from '../Detail/DetailCard';
import { cancelMaster } from '../../../services/offerRequest/master';
import { getDetailList } from '../../../services/offerRequest/detail';
import { EmptyState } from '../../Elements/EmptyState';
import { COLORS } from '../../../assets/css/_colors';
import { ReactComponent as AddIcon } from '../../../assets/images/plus.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete.svg';

const Wrapper = styled.div`
    max-width: 1000px;
    margin: 50px auto;
    margin-bottom: 100px;
    border-radius: 5px;
    background-color: transparent;

    :last-child {
        margin-bottom: 0px;
    }

    label {
        font-size: 17px;
    }

    @media (max-width: 480px) {
        label {
            font-size: 15px;
        }
    }
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 50%);
    margin: 0 40px;
    padding: 0 0px 10px 0px;

    @media (max-width: 767px) {
        grid-template-columns: ${(props) =>
            props.oneColumn ? '100%' : 'repeat(2, 50%)'};

        margin: 0 20px;
    }

    @media (max-width: 480px) {
        grid-template-columns: 100%;

        .dontShowOnMobile {
            display: none;
        }
    }
`;

const Element = styled.data`
    width: ${(props) => props.width};
    position: relative;
    padding: 15px 0;
    color: ${COLORS.green};
    font-weight: bold;

    @media (max-width: 767px) {
        width: 100%;
        text-align: ${(props) =>
            props.oneColumn ? 'start !important' : 'end'};
    }

    @media (max-width: 480px) {
        width: 100%;
    }
`;

const Date = styled.div`
    color: ${COLORS.green};
    padding: 20px 40px;
    margin-bottom: 0;

    @media (max-width: 767px) {
        padding: 20px;
    }
`;

const Buttons = styled.div`
    display: flex;
    padding: 20px 0;

    @media (max-width: 767px) {
        padding: 20px 0;
    }

    @media (max-width: 480px) {
        display: grid;
        grid-template-columns: repeat(2, 50%);
    }
`;

const Card = styled.div`
    border: 1px solid ${COLORS.grayLight};
    border-radius: 4px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: 2;

    .element {
        font-weight: bold;
        color: ${COLORS.green};
        padding: 20px 40px;
        margin-bottom: 0;

        @media (max-width: 767px) {
            padding: 20px;
        }

        @media (max-width: 460px) {
            &__desktop {
                display: none;
            }
        }
    }
`;

const MasterCard = ({ master, status }) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const [addBtnColor, setAddBtnColor] = useState(`${COLORS.grayLight}`);
    const [deleteBtnColor, setDeleteBtnColor] = useState(`${COLORS.grayLight}`);
    const history = useHistory();
    const year = master.year;
    const number = master.number;
    const [open, setOpen] = useState(false);
    const [detailList, setDetailList] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const data = await getDetailList(token, year, number);
            data.forEach((element) => {
                var item = {
                    id: element.Id,
                    year: element.MasterYear,
                    number: element.MasterNumber,
                    position: element.Position,
                    description: element.Description,
                    response: element.Response,
                    status: element.Status,
                    statusName: element.StatusName,
                    articleName: element.ArticleName,
                    articleId: element.ArticleId,
                    quantity: element.RequiredQuantity,
                };
                setDetailList((prevState) => [...prevState, item]);
            });
        } catch (e) {
            console.log(e);
        }
    };

    const onMasterDeleteClick = async () => {
        try {
            await cancelMaster(token, year, number);
            window.location.reload(false);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Wrapper>
            <Card>
                <div className='element'>
                    <label>Datum: </label>
                    <label style={{ color: 'black' }}>{master.date}</label>
                </div>

                <div className='element element__desktop'>
                    <label>Godina: </label>
                    <label style={{ color: 'black' }}>{master.year}</label>
                    <label style={{ marginLeft: '20px' }}>Broj: </label>
                    <label style={{ color: 'black' }}>{master.number}</label>
                </div>

                <div className='element element__desktop'>
                    <label>Satus: </label>
                    <label style={{ color: 'black' }}>
                        {master.statusName}
                    </label>
                </div>
                <div className='element'>
                    <label>Lokacija: </label>
                    <label style={{ color: 'black' }}>
                        {master.clientName}
                    </label>
                </div>
            </Card>

            {master.status === 0
                ? detailList.map((detail, index) => {
                      return <DetailCard key={index} detail={detail} edit />;
                  })
                : detailList.map((detail, index) => {
                      return <DetailCard key={index} detail={detail} />;
                  })}
            {detailList.length === 0 && (
                <EmptyState msg={'Nema prijavljenih upita na ovom masteru!'} />
            )}
            <Buttons>
                {master.status === 0 && detailList.length === 0 && (
                    <ButtonBasic
                        style={{
                            marginRight: '10px',
                        }}
                        type='button'
                        onClick={(e) => setOpen(true)}
                        onMouseEnter={() => setDeleteBtnColor('white')}
                        onMouseLeave={() =>
                            setDeleteBtnColor(`${COLORS.grayLight}`)
                        }
                    >
                        <DeleteIcon fill={deleteBtnColor} />
                        <span
                            style={{
                                marginLeft: '10px',
                                alignSelf: 'center',
                            }}
                        >
                            Obriši
                        </span>
                    </ButtonBasic>
                )}
                {master.status === 0 && (
                    <ButtonBasic
                        style={{}}
                        type='button'
                        onClick={(e) =>
                            history.push(
                                `/new/detail/${year}/${number}/empty/false`
                            )
                        }
                        onMouseEnter={() => setAddBtnColor('white')}
                        onMouseLeave={() =>
                            setAddBtnColor(`${COLORS.grayLight}`)
                        }
                    >
                        <AddIcon width='25px' fill={addBtnColor} />
                        <span
                            style={{
                                marginLeft: '10px',
                                alignSelf: 'center',
                            }}
                        >
                            Dodaj
                        </span>
                    </ButtonBasic>
                )}
                {(master.status === 1 || master.status === 2) && (
                    <ButtonPDF download href='../../assets/PSFsAndSampling.pdf'>
                        DOWNLOAD PDF
                    </ButtonPDF>
                )}
            </Buttons>

            <DialogWarning
                open={open}
                onClose={() => setOpen(false)}
                title={'Upozorenje!'}
                message={
                    'Jeste sigurni da želite obrisati master upita za ponudom?'
                }
                onClickCancel={() => setOpen(false)}
                onClickOk={onMasterDeleteClick}
                cancelText={'odustani'}
                okText={'obriši'}
            />
        </Wrapper>
    );
};

export default MasterCard;

import React from 'react';
import styled from 'styled-components';
import alertIcon from '../../assets/images/alert.svg';

const Container = styled.div`
    display: inline-flex;
    justify-content: center;
    width: 100%;
`;

const Warning = ({ msg }) => {
    return (
        <Container>
            <img
                style={{ width: '20px', marginRight: '10px' }}
                src={alertIcon}
            />
            <p style={{ color: 'gray' }}>{msg}</p>
        </Container>
    );
};
export default Warning;

import React, { createContext, useState } from 'react';

export const SelectedGroupContext = createContext(null);
export const SearchedArticleContext = createContext(null);
export const ArticleListContext = createContext(null);
export const CartContext = createContext(null);
export const LoadingCartContext = createContext(null);

export const LoadingCartProvider = (props) => {
    const [loading, setLoading] = useState(false);

    return (
        <LoadingCartContext.Provider value={[loading, setLoading]}>
            {props.children}
        </LoadingCartContext.Provider>
    );
};

export const CartProvider = (props) => {
    const [cart, setCart] = useState([{ id: '040001', quantity: 5 }]);

    return (
        <CartContext.Provider value={[cart, setCart]}>
            {props.children}
        </CartContext.Provider>
    );
};

export const SelectedGroupProvider = (props) => {
    const [selectedGroup, setSelectedGroup] = useState('01');
    const [selectedGroupName, setSelectedGroupName] = useState('ADAPTERI');

    return (
        <SelectedGroupContext.Provider
            value={[
                selectedGroup,
                setSelectedGroup,
                selectedGroupName,
                setSelectedGroupName,
            ]}
        >
            {props.children}
        </SelectedGroupContext.Provider>
    );
};

export const SearchedArticleProvider = (props) => {
    const [searchedArticle, setSearchedArticle] = useState('');

    return (
        <SearchedArticleContext.Provider
            value={[searchedArticle, setSearchedArticle]}
        >
            {props.children}
        </SearchedArticleContext.Provider>
    );
};

export const ArticleListProvider = (props) => {
    const [articleList, setArticleList] = useState([]);

    return (
        <ArticleListContext.Provider value={[articleList, setArticleList]}>
            {props.children}
        </ArticleListContext.Provider>
    );
};

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ArticleCard from './ArticleCard';
import { getWholeAndDecimal } from '../../../utils/getWholeAndDecimal';
import { COLORS } from '../../../assets/css/_colors';

const ArticleContainer = styled.div`
    margin-top: 40px;
    justify-content: center;
    /* display: inline-flex; */
    display: grid;
    grid-template-columns: repeat(2, auto);

    @media (max-width: 480px) {
        /* flex-wrap: wrap; */
        grid-template-columns: 100%;
        justify-items: center;
    }
`;

const ArticleAmountSaved = styled.div`
    margin-left: 20px;
    color: ${COLORS.grayTextLight};

    @media (max-width: 480px) {
        margin: 0;
    }
`;

const SelectedArticle = ({
    articleName,
    articlePrice,
    measuringUnit,
    articleQuantity,
    price,
}) => {
    const [wholePrice, setWholePrice] = useState(0);
    const [decimalPrice, setDecimalPrice] = useState(0);

    useEffect(() => {
        const wholeArticlePrice = getWholeAndDecimal(articlePrice);
        setWholePrice(wholeArticlePrice[0]);
        setDecimalPrice(wholeArticlePrice[1]);
    }, []);

    return (
        <div>
            <ArticleContainer>
                <div style={{ width: '200px' }}>
                    <ArticleCard
                        name={articleName}
                        wholePrice={wholePrice}
                        decimalPrice={decimalPrice}
                        style={{
                            margin: '0',
                            border: `1px solid ${COLORS.grayLight}`,
                        }}
                    />
                </div>
                <ArticleAmountSaved>
                    <p>Mjerna jedinica: {measuringUnit}</p>
                    <p>Količina: {articleQuantity}</p>
                    <p>
                        Iznos:{' '}
                        <span
                            style={{
                                color: `${COLORS.grayTextDark}`,
                                fontWeight: 'bold',
                            }}
                        >
                            {price} kn
                        </span>
                    </p>
                </ArticleAmountSaved>
            </ArticleContainer>
        </div>
    );
};
export default SelectedArticle;

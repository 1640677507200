import { API_URL } from "../../constants/index";

export async function getArticleById(token, userId, articleId) {
    try {
        const res = await fetch(`${API_URL}/article/${articleId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Token: token,
                UserId: userId,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function getArticleList(token, userId) {
    try {
        const res = await fetch(`${API_URL}/article/list`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Token: token,
                UserId: userId,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function getArticleListByGroup(token, userId, group) {
    try {
        const res = await fetch(`${API_URL}/article/list/${group}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Token: token,
                UserId: userId,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

export async function getArticleGroupList(token, userId) {
    try {
        const res = await fetch(`${API_URL}/article/group/list`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Token: token,
                UserId: userId,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            throw json.Message;
        }
    } catch (e) {
        throw e;
    }
}

import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { ButtonBasic } from '../../Elements/CustomButton';
import ArticleCard from '../ChoseArticles/ArticleCard';
import ArticleQuantity from './ArticleQuantity';
import { getArticleById } from '../../../services/offerRequest/article';
import { getWholeAndDecimal } from '../../../utils/getWholeAndDecimal';
import { Spinner } from '../../Elements/Spinner';
import { DataContext } from '../../../context/ArticleContext';
import { COLORS } from '../../../assets/css/_colors';
import deleteIcon from '../../../assets/images/delete.svg';

const ArticleContainer = styled.div`
    margin-top: 40px;
    display: inline-flex;
    justify-content: center;

    @media (max-width: 480px) {
        flex-wrap: wrap;
    }
`;

const SelectArticle = ({ articleId, articlesUrl, edit }) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = JSON.parse(localStorage.getItem('userId'));
    let { year, number, id } = useParams();
    const history = useHistory();
    const [wholePrice, setWholePrice] = useState('');
    const [decimalPrice, setDecimalPrice] = useState('');
    const [loading, setLoading] = useState(true);
    const {
        setSelectedArticleId,
        setArticleQuantity,
        setArticlePrice,
        setOfferPrice,
        articleName,
        setArticleName,
        setArticleMeasuringUnit,
    } = useContext(DataContext);

    useEffect(() => {
        if (articleId === 'empty' || articleId === undefined) {
            setLoading(false);
        } else {
            fetchArticleInfo();
            setSelectedArticleId(articleId);
        }
    }, []);

    const fetchArticleInfo = async () => {
        try {
            const data = await getArticleById(token, userId, articleId);
            var price = getWholeAndDecimal(data.Price.toFixed(2));
            setWholePrice(price[0]);
            setDecimalPrice(price[1]);
            setArticlePrice(data.Price);
            setArticleName(data.Name);
            setArticleMeasuringUnit(data.MeasuringUnit);
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    function onDeleteArticleClick(e) {
        setSelectedArticleId('');
        setOfferPrice(0);
        setArticleQuantity(1);
        setArticlePrice(0);
        setArticleMeasuringUnit('');
        setArticleName('');

        if (id) {
            history.push(`/edit/detail/${year}/${number}/${id}/empty/true`);
        } else {
            year
                ? history.push(`/new/detail/${year}/${number}/empty/true`)
                : history.push(history.push(`/new/detail/empty/empty`));
        }
    }

    return (
        <div>
            {loading ? (
                <Spinner style={{ marginTop: '50px' }} />
            ) : edit ? (
                <div>
                    {articleId && articleId !== 'empty' && (
                        <ArticleContainer>
                            <div style={{ width: '200px' }}>
                                <ArticleCard
                                    name={articleName}
                                    wholePrice={wholePrice}
                                    decimalPrice={decimalPrice}
                                    onClick={() =>
                                        history.push(`${articlesUrl}`)
                                    }
                                />

                                <ButtonBasic
                                    style={{
                                        // marginLeft: '5px',
                                        width: '200px',
                                        marginTop: '20px',
                                        display: 'inline-flex',
                                        textAlign: 'center',
                                    }}
                                    type='button'
                                    onClick={onDeleteArticleClick}
                                >
                                    <img src={deleteIcon} />
                                    <span
                                        style={{
                                            marginLeft: '20px',
                                            alignSelf: 'center',
                                        }}
                                    >
                                        Obrisi artikl
                                    </span>
                                </ButtonBasic>
                            </div>
                            <ArticleQuantity selectedArticle={true} />
                        </ArticleContainer>
                    )}

                    {!articleId && articleId !== 'empty' && (
                        <ButtonBasic
                            style={{ width: '200px', marginTop: '50px' }}
                            type='button'
                            onClick={() => history.push(`${articlesUrl}`)}
                        >
                            Odaberi artikl
                        </ButtonBasic>
                    )}
                    {articleId === 'empty' && (
                        <div>
                            <ButtonBasic
                                style={{ width: '200px', marginTop: '50px' }}
                                type='button'
                                onClick={() => history.push(`${articlesUrl}`)}
                            >
                                Odaberi artikl
                            </ButtonBasic>
                            <ArticleQuantity
                                text='ODABERITE KOLIČINU OPISANOG ARTIKLA'
                                style={{ marginTop: '20px', marginLeft: '0' }}
                                selectedArticle={false}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <ArticleContainer>
                    <div style={{ width: '200px' }}>
                        <ArticleCard
                            name={articleName}
                            wholePrice={wholePrice}
                            decimalPrice={decimalPrice}
                            style={{
                                margin: '0',
                                border: `1px solid ${COLORS.grayLight}`,
                            }}
                        />
                    </div>
                </ArticleContainer>
            )}
        </div>
    );
};
export default SelectArticle;

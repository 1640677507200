import React, { useEffect, useContext } from 'react';
import Footer from '../../Footer/Footer';
import ArticlesList from '../../OfferRequest/ChoseArticles/ArticlesList';
import { DefaultInfo } from '../../Elements/DefaultInfo';
import { Wrapper } from '../../../assets/css/_pages';
import { SelectedGroupProvider } from '../../../context/WebShopContext';

const ArticlesPage = ({}) => {
    return (
        <Wrapper>
            <DefaultInfo />
            <SelectedGroupProvider>
                <ArticlesList />
            </SelectedGroupProvider>
            <Footer />
        </Wrapper>
    );
};
export default ArticlesPage;

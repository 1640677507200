import { API_URL } from "../../constants/index";

export async function handleLogin(username, password) {
    try {
        const res = await fetch(`${API_URL}/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                Username: username,
                Password: password,
            }),
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            const response = {
                Message: json.Message,
                status: res.status,
            };
            throw response;
        }
    } catch (e) {
        throw e;
    }
}

export function sort(array, sortBy) {
    array.sort(function (a, b) {
        switch (sortBy) {
            case 1: {
                var nameA = a.Price;
                var nameB = b.Price;
                if (parseFloat(nameA) < parseFloat(nameB)) return -1;
                if (parseFloat(nameA) > parseFloat(nameB)) return 1;
                return 0;
            }
            case 2: {
                var nameA = a.Price;
                var nameB = b.Price;
                if (parseFloat(nameA) > parseFloat(nameB)) return -1;
                if (parseFloat(nameA) < parseFloat(nameB)) return 1;
                return 0;
            }
            case 3: {
                var nameA = a.Name.toUpperCase();
                var nameB = b.Name.toUpperCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            }
            case 4: {
                var nameA = a.Name.toUpperCase();
                var nameB = b.Name.toUpperCase();
                if (nameA > nameB) return -1;
                if (nameA < nameB) return 1;
                return 0;
            }
        }
    });
    return [...array];
}
